.legend-icon-hr {
    display: inline-block;
	width: 27px;
	height: 0px;
	border-top: 2px solid #008DFF;
  }

.legend-icon-rectangle {
    display: inline-block;
	width: 5px;
	height: 5px;
	left: 267px;

	background: #E3ECFD;
	border-radius: 1px;
  }

.legend-icon {
    display: flex;
	justify-content: center;
}

