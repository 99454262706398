.custom-checkbox {
	width: 100%;
	max-width: fit-content;
	height: auto;
	margin-bottom: 6px;
}

.custom-checkbox input {
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;
}

.custom-checkbox label {
	position: relative;
	cursor: pointer;
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: #212121;
	padding-left: 22px;
	user-select: none;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	word-break: break-all;
	text-overflow: ellipsis;
}

.custom-checkbox label:before {
	content: '';
	width: 16px;
	height: 16px;
	-webkit-appearance: none;
	background-color: transparent;
	background-image: none;
	background-position: 40% center;
	background-repeat: no-repeat;
	border: 1px solid #99a0a8;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 4px;
	display: inline-block;
	cursor: pointer;
	margin-right: 8px;
}

/* .custom-checkbox input:checked+label:after {
    content: '';
    display: block;
    position: absolute;
    top: 4px;
    left: 5px;
    width: 5px;
    height: 9px;
    border: solid #3D8BF8;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
} */

.custom-checkbox input:checked+label:before {
	border: 1px solid #3d8bf8;
	background-image: url('../../../public/icons/checkbox-tick-icon.svg');
}

.custom-checkbox-disabled label {
	color: #99a0a8;
	cursor: not-allowed;
}

.custom-checkbox-disabled label:before {
	cursor: not-allowed;
}