.ozAdminMappingContainer .mappingsMorevertIcon {
    display: none;
    font-size: large;
    cursor: pointer;
}

/* ag-grid adds ag-row-hover class on row hover */
.ozAdminMappingContainer .ag-row-hover .mappingsMorevertIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
}

.ozAdminMappingContainer {
    margin-right: 8px;
    margin-left: 9px;
}

.oz-mappings-morevert-column-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}