.ozAdminGroupsContainer .groupsMorevertIcon {
    display: none;
}

.ozAdminGroupsContainer .ag-row-hover .groupsMorevertIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
    cursor: pointer;
}

.ozAdminGroupsContainer {
    margin-left: 8px;
    margin-right: 8px;
}

.oz-group-description-container {
    width: 500px;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.oz-group-name-container {
    color: #3D8BF8;
    cursor: pointer;
}

.oz-group-members-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.oz-group-form-label {
    color: #212121;
    font-size: 14px;
    font-weight: 400;
}

.oz-group-form-header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
    margin: 15px 15px 15px 10px;
    justify-content: space-between;
}

.oz-group-form-content {
    margin-left: 10px !important;
}

.ozGroupAddForm .agent-subadmin-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 6px;
    margin-left: 1px;
    color: #212121;
}

.ozGroupAddForm .previousButton {
    color: #536580;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
}

.ozGroupAddForm .createButton {
    background-color: #3D8BF8;
    border-radius: 8px;
    height: 44px;
    font-size: 14px;
    font-weight: 400;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
}

.ozAdminGroupsContainer .oz-page-header {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    width: 37px;
    height: 14px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}

.ozAdminGroupsContainer .oz-page-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.13px;
    width: 78px;
    height: 28px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}