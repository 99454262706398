.schedule-container {
    /* width: 400px; */
    padding: 20px;
    background-color: #f8f9fa;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
  
.work-day {
    margin-bottom: 15px;
    padding: 10px;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}
  
.work-day-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}
  
.time-slot {
    display: flex;
    align-items: center;
    margin-top: 10px;
  }
  
input[type="time"] {
    margin-right: 10px;
  }
  
.delete-slot, .delete-day, .add-slot {
    color: red;
    cursor: pointer;
    font-size: 1.2em;
    justify-content: end;
    display: flex;
}
  
.add-day {
    display: flex;
    justify-content: flex-end;
}
  
.add-day-btn {
    background-color: #007bff;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1em;
}

.custom-time-picker .rc-time-picker-input {
    border-radius: 8px !important;
    background: #FFFFFF !important;
    border: 1px solid #D6D6D7 !important;
    height: 34px !important;
    font-size: 14px !important;
    color: #212121 !important;
    padding-left: 14px !important;
    width: 75% !important;
}

.image-icon-disable {
    filter: invert(79%) sepia(32%) saturate(14%) hue-rotate(328deg) brightness(99%) contrast(109%);
}