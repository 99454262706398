.chat-history-main-container {
	font-family: 'Roboto';
	font-style: normal;
	color: #212121;
}
.chat-history-heading-container {
	padding: 10px 20px 24px 20px;
}
.chat-history-heading {
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
}
.agent-name-style {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	margin-top: 15px;
}
.date-container {
	height: 30px;
	background-color: #f2f2f2;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #536580;
	padding: 0px 16px 0px 12px;
}
.agent-chat-container {
	font-family: 'Roboto';
	font-style: normal;
	padding: 24px 0px 0px 12px;
}
.message-sender {
	display: block;
	font-weight: 500;
	font-size: 10px;
	line-height: 12px;
	color: #536580;
	margin-bottom: 4px;
}
.message-container {
	border-radius: 0px 10px 10px 10px;
	background: #f5fbff;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
	padding: 12px;
	display: flex;
	align-items: center;
	margin-right: 8px;
}
.message-container-customer {
	border-radius: 0px 10px 10px 10px;
	background: #EBEEF2;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
	padding: 12px;
	display: flex;
	align-items: center;
	margin-right: 8px;
}
.message-time {
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #99a0a8;
}
.customer-chat-container {
	font-family: 'Roboto';
	font-style: normal;
	padding-right: 12px;
	margin-top: 12px;
}
.customer {
	display: flex;
	justify-content: flex-end;
}
.customer-time {
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #99a0a8;
	margin-right: 8px;
}
.customer-container {
	border-radius: 10px 0px 10px 10px;
	margin-right: 0px;
}
.session-id {
	font-weight: 400;
}

.chat-history-img {
	width: 300px;
	height: 220px;
}

.chat-history-video {
	width: 300px !important;
	height: 220px !important;
}

/* .chat-history-img { //code which is modified 
	width: 250px;
	height: 180px;
}

.chat-history-video {
	width: 250px;
	height: 180px;
} */

.chat-History-agent a {
	color: black !important;
	background-color: transparent;
	text-decoration: none;
}

.chat-History-agent a:hover {
	text-decoration: underline;
}

.chat-History-user>a {
	color: black !important;
	background-color: transparent;
	text-decoration: none;
}

.chat-History-user a:hover {
	text-decoration: underline;
	;
}
