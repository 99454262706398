.mt-88 {
	margin-top: 88px !important;
}

.mb-26 {
	margin-bottom: 26px !important;
}

.mb-96 {
	margin-bottom: 96px !important;
}

.vector-backgroung-img-left {
	position: absolute;
	width: 48%;
	height: 100%;
	left: -4.8%;
	bottom: 0%;
}

.vector-backgroung-img-right {
	position: absolute;
	height: 96%;
	right: 0px;
}

.forget-password-bx p.grey_p {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
	text-align: center;
	color: #536580;
}

.login_container {
	width: 100%;
	height: auto;
	min-height: 100vh;
	box-sizing: border-box;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 60px;
	justify-content: space-between;
	background-color: #E5E5E5;
	background-repeat: no-repeat;
	background-position: top left;
}



.request-otp-container {
    width: 320px;
    padding: 30px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0,0,0,0.1);
  }
 
  .oz-tb-otp-header {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
 
  .oz-tb-otp-back-button {
    background: none !important;
    border: none !important;
    color: #3D8BF8 !important;
    cursor: pointer !important;
    padding: 0px !important;
    display: flex !important;
    align-items: flex-start !important;
    justify-content: left !important;
    font-size: 14px !important;
    font-weight: 400 !important;
    line-height: 16.41px !important;
    text-align: left;
  }
 
.oz-tb-otp-header-label {
    width: 65%;
    display: flex;
    justify-content: center;
    font-size: 24px !important;
    font-weight: 600 !important;
    line-height: 28.13px !important;
    text-align: left;
}
.oz-tb-otp-sip-no-email-found-icon{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 20px;
    }
 
.oz-tb-otp-sip-no-email-found-label{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-flow: column;
    margin: 20px;
    font-size: 16px;
    font-weight: 400;
    line-height: 18px;
    text-align: center;
    color: #212121;
    }
.oz-tb-otp-receive-otp-label{
    color: #212121 !important;
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 30px !important;
    text-align: center;
    margin-bottom: 5px !important;
}
    .oz-tb-otp-to-sms{
    font-size: 16px  !important;
    font-weight: 500  !important;
    line-height: 21.09px  !important;
    text-align: left  !important;
    color: #0C0E12 !important;
    margin-left: 10px !important;
    }
    .oz-tb-otp-to-email{
        font-size: 16px  !important;
        font-weight: 500  !important;
        line-height: 21.09px  !important;
        text-align: left  !important;
        color: #0C0E12 !important;
        margin-left: 10px !important;
    }
 
  .oz-tb-otp-lock-icon {
    display: flex;
    justify-content: center;
    margin: 30px 0;
  }
 
  .otp-methods {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-bottom: 20px;
  }
  .oz-tb-otp-main-header-container{
    display: flex;
    width: 100%;
    padding: 15px 0px;
  }
  .oz-tb-otp-main-header-label{
    width: 65%;
    display: flex;
    justify-content: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 28.13px;
    text-align: left;
  }
  .oz-tb-otp-verification-container{
    padding: 10px 35px;
    width: 320px;
  }
  .oz-tb-otp-verify-back-button{
    display: flex !important;
    text-align: center;
    align-items: center !important;
    padding: 0px !important;
    border-radius: 50px !important;
    margin-left: -11px !important;
  }
  .oz-tb-otp-verification-back-button-icon{
    margin-top: 0px;
  }
  .oz-tb-otp-request-button {
    font-weight: 400 !important;
    text-align: center;
    width: 100%;
    padding: 10px !important;
    background-color: #007bff !important;
    color: white !important;
    border: none !important;
    border-radius: 5px !important;
    font-size: 16px !important;
    cursor: pointer !important;
  }
 
  .request-button:hover {
    background-color: #0056b3;
  }
  .oz-tb-otp-timer-label{
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    margin-top: 10px;
  }
 
.oz-tb-otp-icon-and-label{
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}
.oz-tb-email-otp-verification-label{
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    margin: 10px 0px;
}
.oz-tb-otp-not-yet-received-label{
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    color: #212121;
 
}
.oz-tb-otp-resend-timer-box{
    display: flex;
}
.oz-tb-otp-resend-otp-in-label{
    display: flex;
    font-size: 12px;
    font-weight: 400;
    line-height: 14.06px;
    text-align: left;
    align-items: center;
    justify-content: center;
    margin-left: 5px;
    color: #008DFF;
 
}

.d-flex{
     display: flex;
}

.login_container .login-right {
	margin-right: 32px !important;
	position: absolute;
	width: 454px;
	height: auto;
	min-height: 100px;
	margin: 0;
	padding: 24px 20px;
	background-color: #fff;
	border: 1px solid #e6e5e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
	border-radius: 12px;
	//z-index: 1390;
	right: 4.5%;
}

.login_container .login-right .login-form-title {
	font-weight: 600;
	font-size: 24px;
	line-height: 28px;
	color: #000000;
}

.login_container .login-left {
	position: absolute;
	left: 130px;
	width: 550px;
	height: auto;
	//z-index: 9999;
}

@media (min-width: 1232px) {
	.login_container .login-right {
		width: 450px !important;
	}

	.common-prop-header {
		width: 85% !important;
		max-height: 200px;
		min-height: 100px;
	}

	.login-title-white {
		font-size: 32px !important;
	}

	/* .login_container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		padding: 0 60px;
	}

	.login-left {
		min-width: 200px;
		max-width: 500px;
	}

	.login-right {
		min-width: 450px;
		max-width: 600px;
		margin: 0;
		background-color: #fff;
		border: 1px solid #e6e5e6;
		box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
		border-radius: 12px;
	} */
}

@media (min-width: 992px) and (max-width: 1231px) {

	.login-left {
		min-width: 200px;
		max-width: 500px;
	}

	.login_container .login-right {
		width: 454px !important;
	}

	.login-sub-title-white {
		width: 80%;
	}

	.login_container .login-left {
		left: 28px !important;
		width: 411px !important;
	}
}

@media (min-width: 850px) and (max-width: 991px) {
	.login_container {
		width: 100%;
		height: 100%;
		min-height: 100vh;
		padding: 0 60px;
	}

	.login-left {
		min-width: 200px;
		max-width: 550px;
		margin-left: 6%;
	}

	.login_container .login-left {
		position: static;
		left: -130px;
		width: 400px;
		height: auto;
		z-index: 1;
	}

	.login-sub-title {
		width: 300px !important;
	}

	.login-left .login-sub-title {
		font-size: 20px !important;
	}

	.login_container .login-right {
		padding: 15px 19px !important;
	}
}

@media (min-width: 720px) and (max-width: 849px) {


	.login_container .login-left .login-title {
		font-size: 35px !important;
	}

	.login_container .login-left .login-sub-title {
		font-size: 20px !important;
	}

	.login-sub-title {
		width: 320px !important;
	}

	.login_container .login-left {
		left: 0px;
		z-index: 1;
		width: 300px;
		position: unset;
	}

	.login_container .login-right {
		padding: 15px 20px;
	}

	.login-container-blue {
		height: 180% !important;
		min-width: 100vh !important;
	}
}

@media (min-width: 650px) and (max-width: 719px) {
	.common-prop-header {
		width: 60% !important;
	}


	.login_container .login-left .login-title {
		font-size: 30px !important;
	}

	.login_container .login-left .login-sub-title {
		font-size: 20px !important;
	}

	.login-sub-title {
		width: 190px !important;
	}

	.login_container .login-left {
		left: 0px;
		z-index: 1;
		width: 300px;
		position: unset;
	}

	.login_container .login-right {
		padding: 10px 12px !important;
	}

	.login-container-blue {
		height: 200% !important;
		min-width: 100vh !important;
	}

	.css-1ho11xt-MuiButtonBase-root-MuiButton-root-MuiLoadingButton-root {
		margin-bottom: 15px !important;
	}
}

@media (min-width: 500px) and (max-width: 649px) {

	.login_container {
		display: grid !important;

	}

	.login_container .login-left {
		display: grid !important;
		margin-bottom: 25px;

	}

	.login-container-blue {
		height: 320% !important;
		width: 120% !important;
	}

	.login_container .login-right {
		position: unset;
		width: 450px !important;
	}

	.login_container .login-left {
		position: unset;

	}

	.login_container .login-left .login-title {
		font-size: 35px !important;
	}
}

@media (min-width: 466px) and (max-width: 499px) {
	.login_container {
		display: grid !important;

	}

	.login_container .login-left {
		display: grid !important;
		position: unset;
		margin-bottom: 30% !important;

	}

	.login-container-blue {
		width: 130% !important;
		height: 350% !important;
	}

	.login_container .login-right {
		position: unset;
		width: 450px !important;
	}

}

@media (min-width: 400px) and (max-width: 465px) {
	.login_container {
		display: grid !important;

	}

	.login_container .login-left {
		display: grid !important;
		position: unset;
		margin-bottom: 40px;

	}

	.login-container-blue {
		height: 390% !important;
		width: 150% !important;
	}

	.login_container .login-right {
		position: unset;
		width: 450px !important;
	}
}

@media (min-width: 200px) and (max-width: 400px) {
	.login_container {
		display: grid !important;

	}

	.login_container .login-left {
		display: grid !important;

	}

	.login-container-blue {
		height: 600% !important;
		width: 180% !important;
	}

	.login_container .login-right {
		position: unset;
		width: 450px !important;
	}

	.login_container .login-left {
		position: unset;

	}
}

.login-right {
	min-width: 300px;
	max-width: 600px;
	margin: 0;
	background-color: #fff;
	border: 1px solid #e6e5e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
	border-radius: 12px;
}

.login_container .login-right {
	margin-right: 0px !important;
	width: 300px;

}


.login_container .login-left .login-title {
	font-weight: 600;
	font-size: 38px;
	line-height: 45px;
	color: #000000;
	margin: 32px 0 16px;
}

.login-title-white {
	color: #FFFFFF !important;
}

.copyright-img {
	position: absolute;
	bottom: 15px;
	left: 43%;
	display: flex;
	justify-content: center;
}

.green-circle-top-left {
	position: absolute;
	top: -50px !important;
}

.login_container .login-left .login-sub-title {
	font-weight: 400;
	font-size: 22px;
	line-height: 30px;
	color: #99A0A8;
}

.login-sub-title {
	width: 430px
}

.customLoginTab {
	border-bottom: 1px solid #99a0a8;
	margin-top: 20px;
}

.customLoginTab button {
	width: 50%;
	font-weight: 600;
	font-size: 18px;
	line-height: 21px;
	color: #99a0a8;
}

.ozonetel-form-control {
	width: 100%;
	height: auto;
	margin-top: 24px;
}

.ozonetel-form-control-inline {
	width: 100%;
	height: auto;
	margin-top: 24px;
	display: flex;
	align-items: center;
	justify-content: space-between;
}

.ozonetel-form-control-inline.column {
	flex-direction: column;
	margin-top: 16px;
}

.admin-header-logo {
	width: 85%;
}

.ozonetel-form-control-inline.column span {
	margin-bottom: 16px;
}

.ozonetel-form-control-inline .custom-checkbox {
	margin-bottom: 0;
}

.ozonetel-form-control label {
	display: block;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
	margin-bottom: 8px;
}

.ozonetel-form-control input {
	width: 100%;
	min-height: 44px;
	background: #ffffff;
	border: 1px solid #d6d6d7;
	color: #212121;
	border-radius: 8px;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	padding: 0 12px;
	box-sizing: border-box;
	outline: none;
}

.ozonetel-form-control input:focus {
	border-color: rgba(0, 141, 255, 1);
}

.loginTabContainer {
	padding: 0 !important;
}

.ozonetel-form-control-inline a {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #3d8bf8;
	text-decoration: none;
	position: relative;
}

.ozonetel-form-control-inline a::after {
	content: '';
	position: absolute;
	width: 0;
	height: 1px;
	background-color: #3d8bf8;
	left: 0;
	bottom: -2px;
	z-index: 1;
	transition: all 0.3s ease-in-out;
	-webkit-transition: all 0.3s ease-in-out;
	-moz-transition: all 0.3s ease-in-out;
	-ms-transition: all 0.3s ease-in-out;
	-o-transition: all 0.3s ease-in-out;
}

.ozonetel-form-control-inline a:hover::after {
	width: 100%;
}

.loginBtn {
	width: 195px;
}

.loginBtnLg {
	width: 100%;
	border-radius: 8px !important;
}

.otherBtns {
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 8px;
}

.otherBtns .custom-social-btn {
	width: 90px;
	height: 44px;
	background: #ffffff;
	border: 1px solid #d6d6d7;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.otherBtnsLg {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 20px;
}

.otherBtnsLg .custom-social-btn {
	width: 50%;
	height: 44px;
	background: #ffffff;
	border: 1px solid #d6d6d7;
	border-radius: 8px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
}

.otherBtnsLg .custom-social-btn span {
	margin: 0 0 0 10px;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #212121;
}

.positionRelative {
	position: relative;
}

/* 03 - 08 - 2022 */

.login-form-title {}

.rememberMe-label {
	padding-left: 8px !important;
	font-size: 14px !important;
	color: #212121 !important;
	font-weight: 400 !important;
}

.rememberMe {
	margin-left: 11px !important;
}

.login-form-title .prev {
	font-family: 'Roboto';
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #3d8bf8;
	position: absolute;
	float: left;
	text-decoration: none;
	padding-top: 7px;
	left: 0;
}

.login-form-title .prev img {
	float: left;
	margin-right: 8px;
	margin-top: 2px;
}

.forget-password-bx {
	text-align: center;
}

.forget-password-bx .login-form-title {
	margin-bottom: 24px;
}

.forget-password-bx p {
	margin: 0 0 16px 0;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
	text-align: center;
	color: #212121;
}

.forget-password-bx p span {
	font-weight: 400;
	font-size: 18px;
	line-height: 30px;
	text-align: center;
	color: #536580;
}

.forget-password-bx img {
	margin: 0 auto;
	margin-bottom: 16px;
}

.forget-password-bx span.know-cred {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
}

.forget-password-bx span.know-cred a {
	color: #008dff;
	text-decoration: none;
}

.forget-password-bx .otp-bx {
	display: flex;
	justify-content: center;
	align-items: center;
	margin-bottom: 45px;
}

.forget-password-bx .otp-bx input {
	background: #ffffff;
	border: 1px solid #d6d6d7;
	border-radius: 8px;
	margin: 0 10px;
	width: 44px;
	height: 44px;
	text-align: center;
	padding: 0;
	line-height: 44px;
}

/*  use this below in case of requirement */

.forget-password-bx label {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
	text-align: left;
}

.forget-password-bx input {
	width: -webkit-fill-available;
	margin-bottom: 45px;
	padding: 14px 12px;
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #99a0a8;
	background: #ffffff;
	border: 1px solid #d6d6d7;
	border-radius: 8px;
	outline: none;
}

.forget-password-bx input::placeholder {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #99a0a8;
}

.forget-password-bx button {
	margin-bottom: 18px;
	background: #3d8bf8;
	border-radius: 8px;
	padding: 14px 0;
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
	width: 100%;
	border: none;
	color: #ffffff;
}

.forget-password-bx .ozonetel-form-control {
	margin-top: 0;
}

.forget-password-bx .ozonetel-form-control input {
	margin-bottom: 24px;
}

.login-container-blue {
	background-color: #008DFF;
}

.login-sub-title-white {
	color: #FFFFFF !important;
	opacity: 0.8 !important;
}

.common-prop-header {
	width: 85%;
	min-height: 100px;
	max-height: 200px;
}

.cci-logo {
	max-height: 150px;
	min-height: 100px;
	width: 45% !important;
	border-radius: 15px 15px 5px 15px;
	/* filter: brightness(0.898); */
}

.loginBtnLg,
.oz-sso-login-btn {
    width: 100%;
    border-radius: 8px !important;
}

.oz-sso-login-btn.MuiButton-root {
    background-color: #FFFFFF;
    border: 1px solid #D6D9DC;
    border-radius: 8px;
    color: #1D242D;
    font-size: 14px;
}

.oz-sso-login-btn.MuiButton-root:hover {
    background-color: #dddcdc;
}