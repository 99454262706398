.oz-data-management-container {
    padding: 10px;
    margin-top: 20px;
    height: calc(100vh - 100px);
}

.oz-data-management-title.MuiTypography-root {
    font-size: 24px;
    font-weight: 500;
    color: #484848;
}

.oz-data-management-tabs-container {
    margin-top: 10px;
    height: 30px;
    width: 100%;
    border-bottom: 1px solid #DAE1E6;
}

.oz-data-management-tabs-container .MuiTab-root {
    min-width: 100px;
    height: 30px;
    font-size: 12px;
    font-weight: 400;
    color: #212121;
    min-height: 0px;
    padding: 0px 8px;
    margin: 0px;
    border: 1px solid #DAE1E6;
}

.oz-data-management-tabs-container .Mui-selected {
    background-color: #FFFFFF;
    color: #212121 !important;
}

.oz-data-management-tabs-container .MuiTabs-indicator {
    height: 0px;
    
}

.oz-data-management-tabs-container .MuiTabs-scroller {
    height: 30px;
}