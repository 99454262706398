.download-item {
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 14px !important;
	color: #212121 !important;
}

.download-item img {
	margin-right: 12px;
}

.custom-dropdown .MuiPaper-root {
	border: 1px solid #e6e5e6 !important;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
	border-radius: 12px !important;
	margin-top: 10px;
}

.xls-download-item {
	font-weight: 400 !important;
	font-size: 12px !important;
	line-height: 14px !important;
	color: #212121 !important;
}

.xls-download img {
	margin-right: 13px;
}