/* .reports-drawer-container .css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
	left: 88px;
	width: 241.17px;
} */

.live-historic-tabs-container {
	/* margin-top: 82px; */
}

.live-historic-tabs-container .css-1gsv261 {
	border: none;
}

.live-historic-tabs-container .historic-tab-content-container {
	padding: 0px;
}

.live-historic-tabs-container .live-button {
	border: 1px solid #dae1e6;
	border-radius: 4px 0px 0px 4px;
	background-color: #f5f8fa;
	height: 30px;
	width: 114px;
}

.tabs-holder .tab-selected {
	border: 1px solid #3d8bf8;
}

.live-button .css-8je8zh-MuiTouchRipple-root {
	border-bottom: none;
}

.box-contianer .css-1csgjbt-MuiTabs-indicator {
	/* background-color: transparent; */
	/* bottom: 8px; */
}

.tabs-holder .css-1csgjbt-MuiTabs-indicator {
	/* background-color: transparent; */
	bottom: 18px;
}

.live-historic-tabs-container .css-13xfq8m-MuiTabPanel-root {
	padding: 0px;
}

.tabs-holder .css-hgv3gf-MuiButtonBase-root-MuiTab-root.Mui-selected {
	background-color: white;
}

.tabs-holder {
	margin-left: 4.8px;
}

.tabs-holder .no-border {
	border-radius: 0px 4px 4px 0px;
}

.tabs-holder .css-hgv3gf-MuiButtonBase-root-MuiTab-root {
	min-height: 0px;
}

.css-hgv3gf-MuiButtonBase-root-MuiTab-root {
	max-width: 600px !important;
}

.historic-tab-content-container .accordian-container {}

.accordian-container .css-1fjvggn-MuiPaper-root-MuiAccordion-root {
	border: none;
}

/* this line written for the Border MUI css to OverWrite in 2nd Tab*/
.accordian-heading {
	border: none !important;
}

.link-styling {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #008dff;
	margin-left: 24.83px;
	margin-bottom: 16px;
	cursor: pointer;
}

.live-link-styling,
.historic-link-styling {
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
	margin: 12px 0 12px 24.83px;
	cursor: pointer;
	display: block;
}

.live-link-styling {
	margin-bottom: 0 0 16px 0;
}

.live-link-styling:hover,
.historic-link-styling:hover,
.historic-link-styling.active,
.live-link-styling.active {
	color: #008dff;
}

.accordian-item {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
	cursor: pointer;
}

.accordian-item:hover,
.acc-sub-item.active {
	color: #008dff;
}

.accordian-heading .expanded-item {
	border: none;
	padding: 0px;
	margin: 0px 0px 12px 37px;
}

.accordian-heading .accordian-summary {
	background-color: white;
	padding: 0px;
	min-height: 0px;
	margin: 0px 0px 0px 14px;
}

.css-uvvswg-MuiButtonBase-root-MuiAccordionSummary-root .MuiAccordionSummary-content {
	margin-left: 4px !important;
}

.acc-sub-item {
	font-weight: 400;
}

.live-reports-bar {
	display: flex;
	flex-direction: column;
}