.oz-widgetReportForm-View-Header {
    font-weight: 400;
    font-size: 12px;
    color: #99A0A8;
}

.oz-widgetReportForm-View-Value {
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: #212121;
    word-break: break-all !important;
    white-space: normal !important;
}

.oz-widgetReportForm-view-morevereticon {
    margin-right: 0.5rem;
    margin-left: 0.625rem;
    align-self: center;
    cursor: pointer;
}