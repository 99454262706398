.ozAdminAddCallWorkContainer .addCallWorkMorevertIcon {
    display: none;
    cursor: pointer;
}

.ozAdminAddCallWorkContainer .ag-row-hover .addCallWorkMorevertIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
    margin-top: -8px;
}

.ozAdminAddCallWorkContainer {
    margin-left: 8px;
    margin-right: 8px;
}

.oz-addCallWork-reason-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.ozAdminAddCallWorkContainer .oz-page-header {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    width: 79px;
    height: 14px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}

.ozAdminAddCallWorkContainer .oz-page-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.13px;
    width: 134px;
    height: 28px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}