.drawer-subtitle {
    width: 75px;
    height: 16px;
    left: 20px;
    top: 57px;

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 300;
    color: #000000;
    line-height: 16px;
}

.drawer-title-badge {
    width: fit-content;
    min-width: 50px;
    height: 18px;
    background-color: #e66b6b;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #ffffff;
    display: inline-block;
    text-align: center;
    margin-left: 10px
}

.drawer-text-container {
    width: 476px;
    background: rgb(217 217 217 / 20%);
    color: black;
    margin-bottom: 200px;
    padding: 0.5rem 0;
}

.drawer-text-container1 {
    width: 476px;
    background: rgb(217 217 217 / 20%);
    color: black;
    padding: 0.5rem 0;
}

.drawer-text-container2 {
    width: 476px;
    color: black;
    padding: 0.5rem 0;
}

.drawer-text-container3 {
    width: 476px;
    background: rgb(217 217 217 / 20%);
    color: black;
    margin-bottom: 200px;
    padding: 0.5rem 0;
}

.drawer-title {
    width: 181px;
    height: 21px;
    left: 20px;
    top: 21px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
}

.drawer-values {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 19px;

    color: #212121;
}

.drawer-names {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    line-height: 16px;

    color: #212121;
}

.drawer-info {
    margin-top: 9px;
    margin-left: 25px;
}

.drawer-table {
    background: #FFFFFF;
    border: 1px solid #E6E5E6;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
}
