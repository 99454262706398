.ozAdminUsersContainer .userMorevertIcon {
    display: none;
    cursor: pointer;
}

.ozAdminUsersContainer .ag-row-hover .userMorevertIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
}

.ozAdminUsersContainer {
    margin-left: 8px;
    margin-right: 8px;
}

.oz-user-Status-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.ozAddSubAdminForm .fieldsStyle {
    padding-top: 20px;
    font-size: 12px;
    color: #212121;
    padding-left: 20px;
    display: flex;
    flex-direction: row;
}

.ozAddSubAdminForm .cancelButton {
    width: 90px;
    height: 44px;
    border: 1px solid #008DFF;
    border-radius: 8px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #FFFFFF;
    font-size: 14px;
    color: #3D8BF8;
    font-weight: 400;
}

.ozAddSubAdminForm .nextButton {
    height: 44px;
    border-radius: 8px;
    padding-top: 14px;
    padding-bottom: 14px;
    padding-left: 24px;
    padding-right: 24px;
    background-color: #3D8BF8;
    font-size: 14px;
    color: #FFFFFF;
    font-weight: 400;
    margin-left: 20px;
}

.ozAddSubAdminForm .previousButton {
    height: 16px;
    font-size: 14px;
    color: #536580;
    font-weight: 400;
    margin-bottom: -10px;
    margin-left: -10px;
}


.ozAddSubAdminForm .modulesPageLabel {
    margin-top: 38px;
    font-size: 14px;
    display: flex;
    color: #000000;
}

.ozAddSubAdminForm .campaignsHeader {
    font-size: 14px;
    display: flex;
    color: #000000;
}

.ozAddSubAdminForm .campaignsLabel {
    font-size: 12px;
    color: #212121;
}

.ozAddSubAdminForm .campaignsPlaceHolder {
    color: #99A0A8;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 9px;
}

.ozAddSubAdminForm .campaignsChip {
    background-color: #F5F5F6;
    color: #536580;
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
    padding-right: 4px;
    margin-left: 12px;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 9px;
    border-radius: 16px;
    margin-bottom: 9px;
}

.ozAddSubAdminForm .campaign-selection-container {
    border: 1px dashed #3D8BF8;
    display: flex;
    border-radius: 8px;
    flex-wrap: wrap;
    min-height: 44px;
}

.ozAddSubAdminForm .campaign-selection-addMode {
    margin-top: 8px;
    margin-left: -4px;
    margin-right: -4px;
}

.ozAddSubAdminForm .editModules-label-spacing {
    width: 210px;
}

.ozAddSubAdminForm .editModules-label-spacing-addMode {
    width: 240px
}

.ozAddSubAdminForm .campaign-selection-editMode {
    margin-right: 16px;
}

.viewMode-fields-header-container {
    display: flex;
    margin-left: 8px;
    margin-top: 16px;
    color: #7C878B;
    line-height: 16px;
    font-size: 14px;
    font-weight: 400;
    align-items: center;
}

.viewMode-fields-header-width {
    min-width: 150px;
    margin-left: 3px;
}

.viewMode-fields-value-color {
    color: #212121;
    font-size: 14px;
    font-weight: 400;
    max-width: fit-content;
    word-break: break-all !important;
}

.oz-user-form-view-maindiv-deactivate {
    margin-top: 4px;
}

.oz-user-view-switch-deactivate-container {
    margin-top: 2px;
    margin-left: 22%;
}

.oz-user-view-switch-container {
    margin-top: 2px;
    margin-left: 27%;
}
.oz-user-view-switch-barge-container {
    margin-top: 2px;
    margin-left: 8.1%;
}

.oz-user-form-view-maindiv {
    display: flex;
    margin-right: 0.5rem;
    margin-left: 0.625rem;
    margin-top: 10px;
}

.oz-user-form-view-editButton {
    justify-content: space-between;
    flex-direction: row;
    margin-right: -10px;
}

.ozAddSubAdminForm .oz-campaingGrid-position {
    margin-right: 8px;
    margin-left: 8px;
    margin-top: 20px;
}

.ozAddSubAdminForm .oz-userTimeZone-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 6px;
    margin-left: 1px;
    color: "#212121";
}

.ozAddSubAdminForm .oz-page-header {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 16px;
    color: #212121;
    font-weight: 400;
    cursor: pointer;
}

.ozAddSubAdminForm .oz-page-heading {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    color: #212121;
    margin-bottom: 24px;
}

.ozAddSubAdminForm .oz-page-roleStyle {
    font-size: 12px;
    font-weight: 500;
    height: 41px;
    max-height: 50px;
    line-height: 41px;
    margin-left: 20px;
}

.ozAdminUsersContainer .oz-page-header {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    width: 37px;
    height: 14px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}

.ozAdminUsersContainer .oz-page-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.13px;
    width: 130px;
    height: 28px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}