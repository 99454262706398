.filter-outer {
    display: flex;
    align-items: center;
}

.filter-outer.filter-items-spacing {
    justify-content: space-between;
}

.filter-outer .filter-inner {
    display: flex;
    align-items: center;
}

.filter-outer .widget-legend {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.filter-outer .widget-legend ul {
    padding: 0;
    margin: 0 40px 0 0;
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 24px;
}

.filter-outer .widget-legend ul li {
    display: flex;
    align-items: center;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #181b1f;
}

.filter-outer .widget-legend ul li img {
    margin-right: 8px;
}



.customCard_WFM {
    width: 100%;
    border: 1px solid #e6e5e6;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
    border-radius: 12px !important;
    margin-bottom: 10px !important;
    /* min-height: 809px; */
    height: 100% !important;
    max-height: 100% !important;
}