.multi-select-custom-checkbox {
	width: 100%;
	border-radius: 4px;
	gap: 6px;
	height: 22px;
	padding:4px 8px 4px 8px;	
}

.multi-select-custom-checkbox :hover{
	background-color: #E6F4FF;
	border-radius: 4px;
}

.multi-select-custom-checkbox input {
	padding: 0;
	height: initial;
	width: initial;
	margin-bottom: 0;
	display: none;
	cursor: pointer;	
}

.multi-select-custom-checkbox label {
	position: relative;
	cursor: pointer;
	font-weight: 400;	
	padding-left: 18px;
	user-select: none;
	display: -webkit-box;
	-webkit-line-clamp: 1;
	word-break: break-all;
	text-overflow: ellipsis;
}
.ozcw-multi-divider{
	margin: 4px 0px !important;
}
.multi-select-custom-checkbox label:before {
	content: '';
	width: 12px;
	height: 12px;
	-webkit-appearance: none;
	background-color: transparent;
	background-image: none;
	background-position: 40% center;
	background-repeat: no-repeat;
	border: 1px solid #99a0a8;
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	border-radius: 4px;
	display: inline-block;
	cursor: pointer;
	margin-right: 2px;	
}

.multi-select-custom-checkbox input:checked+label:before {
	border: 1px solid #3d8bf8;
	background-image: url('../../../public/icons/checkbox-tick-icon.svg');
}