.custom-label .MuiTypography-root {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #212121;
}

.custom-label .MuiCheckbox-root {
	padding: 0 9px;
}

.threshold-items-container {
	width: 100%;
	margin-bottom: 10px;
	height: calc(100vh - 270px);
	padding: 10px 0;
	overflow-y: auto;
}

.threshold-item {
	background: #f6f7f9;
	padding: 10px 20px;
	border-radius: 6px;
	margin-bottom: 34px;
}

.threshold-item-header {
	margin-top: 0;
	margin-bottom: 20px;
	font-size: 14px;
	font-weight: bold;
}

.field-label {
	margin: 0;
	font-size: 12px;
}

.threshold-field {
	width: 148px;
	background: #ffffff;
}

.threshold-field .MuiOutlinedInput-input {
	padding: 4px;
	font-size: 14px;
}

.threshold-action {
	font-size: 12px;
	line-height: 14px;
	color: #3d8bf8;
	cursor: pointer;
}

.threshold-action:not(:last-child) {
	margin-right: 16px;
}

.threshold-button-container {
	flex-direction: row;
	justify-content: center;
	position: fixed;
	bottom: 0;
	background-color: white;
	padding: 20px;
	width: inherit;
}

.threshold-button-container .threshold-button {
	width: 100%;
}

.threshold-button-container .threshold-pagination {
	margin-bottom: 10px !important;
	margin-left: 170px !important;
}