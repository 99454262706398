.MuiTableContainer-root .wallboard-table {
    border: 0.5232px solid #C9C9C9;
}

.MuiTableContainer-root .wallboard-table-head-light {
    background-color: #F4F4F4;
}

.MuiTableContainer-root .wallboard-table-head-dark {
    background-color: #464646;
}

.wallboard-table-head-row .table-head-color-light {
    color: #212121;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20.928px;
    line-height: 25px;
    border: 0.5232px solid #C9C9C9;
    height: 49.7px;
}

.wallboard-table-head-row .table-head-color-dark {
    color: #E1E1E1;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 500;
    font-size: 20.928px;
    line-height: 25px;
    border: 0.5232px solid #C9C9C9;
    height: 49.7px;
}

.css-1kft3ub-MuiTableRow-root .row-cell-color-light {
    color: #212121;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20.928px;
    line-height: 25px;
    border: 0.5232px solid #C9C9C9;
    height: 42.9px;
    padding-top: 9px;
    padding-bottom: 7px;
    background-color: #FFFFFF;
}

.css-1kft3ub-MuiTableRow-root .row-cell-color-dark {
    color: #E1E1E1;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 20.928px;
    line-height: 25px;
    border: 0.5232px solid #C9C9C9;
    height: 42.9px;
    padding-top: 9px;
    padding-bottom: 7px;
    background-color: #212121;
}

.css-1kft3ub-MuiTableRow-root:last-child td,
.css-1kft3ub-MuiTableRow-root:last-child th {
    border: 0.5232px solid #C9C9C9 !important;
}