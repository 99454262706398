.ozAdminPauseReasonContainer .delMoreIcon {
    display: none;
    font-size: large;
    cursor: pointer;
}

/* ag-grid adds ag-row-hover class on row hover */
.ozAdminPauseReasonContainer .ag-row-hover .delMoreIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
}


input[type=time]::-webkit-datetime-edit-ampm-field {
    display: none;
}


/* For input Box */
.rc-time-picker-panel-input-wrap {
    display: none !important;
}

.rc-time-picker-panel-combobox {
    margin-top: 10px;
}

/* Bottom Menu  Css */
.rc-time-picker-panel-select {
    border-width: 0px !important;
    width: 65px !important;
    max-height: 145px !important;
    background: #FFFFFF;
    z-index: 9999 !important;
    border-bottom: 0px !important;
    color: #000000 !important;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 16px !important;
    padding-left: 10px !important;
}

.rc-time-picker-panel-select::-webkit-scrollbar {
    width: 0;
    height: 0;
    background: transparent;
}

/* List Items */
.rc-time-picker-panel-select li {
    width: 36px !important;
    height: 36px !important;
    line-height: 38px !important;
    padding-left: 10px !important;
    margin-left: 12px !important;
    margin-top: 15px !important;
}

.rc-time-picker-panel-select:first-child {
    border: 0px;
}

/* List Items Did Selected */
.rc-time-picker-panel-select-option-selected {
    background: #3D8BF8 !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #FFFFFF !important;
    border-radius: 4px !important;
    font-family: 'Roboto';
    font-style: normal;
}

/* Total Height */
.rc-time-picker-panel {
    width: 220px !important;
    height: 230px !important;
    position: absolute !important;
    border-radius: 8px !important;
    overflow: hidden !important;
    z-index: 9999 !important;
}

/* Bottom Menu */
.rc-time-picker-panel-inner {
    background: #FFFFFF !important;
    box-shadow: none !important;
    position: absolute !important;
    border-radius: 8px !important;
    border: 1px solid #E5E5E5 !important;
    margin-top: 55px !important;
    width: 210px !important
}

/* input box */
.oz-pause-reason-form-container .rc-time-picker-input {
    border-radius: 8px !important;
    background: #FFFFFF !important;
    border: 1px solid #D6D6D7 !important;
    border-radius: 8px !important;
    width: 463px !important;
    height: 44px !important;
    font-size: 14px !important;
    color: #212121 !important;
    padding-left: 14px !important;
}

.rc-time-picker-clear-icon:after {
    display: none !important;
}

/* Not working */
.rc-time-picker-input:focus-visible {
    border: 1px solid #D6D6D7 !important;
}

/* Not Working in Chrome */
.rc-time-picker-input:disabled {
    color: #99A0A8 !important;
    padding-left: 14px;
}

.oz-pause-reason-morevert-column-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.oz-pause-reason-main-container {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
}

.oz-pause-reason-inner-container {
    display: flex;
    align-items: center;
}

.oz-pause-reason-form-container {
    overflow-y: auto;
    width: 29rem;
    height: 257px;
}