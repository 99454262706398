.New template {
    text-align: right;
}

.choose {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #99A0A8;
}

.ozAdminEmailContainer {
    margin-left: 10px;
    margin-right: 8px;
}

.buttonDiscard {

    text-align: right;
}

.icontitle {
    margin-left: 30px;
    margin-right: 18px;
}

.headerItem {
    margin-right: -10px;
}

.ivr-morevert-icons {
    position: absolute;
    left: 97.78%;
    right: 1.96%;
    top: 24.61%;
    bottom: 75.03%;
    color: #99A0A8;
}

.heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;

    color: #99A0A8;
}

.template-type-active {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    margin-right: 270px;
    line-height: 16px;
}

.saveButton {
    /*  width: 89px;
    height: 16px; */

    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
}

.cancelButton {
    box-sizing: border-box;
    background: #FFFFFF;
    border: 1px solid #008DFF;
    border-radius: 8px;
}

.menuCancelButton {
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 24px;
    gap: 10px;
    width: 91px;
    height: 44px;
    background: #FFFFFF;
    border: 1px solid #008DFF;
    border-radius: 8px;
}

.menuSaveButton {
    display: flex;
    width: 104px;
    height: 44px;
    flex-direction: row;
    align-items: flex-start;
    padding: 14px 24px;
    gap: 10px;
    background: #3D8BF8;
    border-radius: 8px;
}

.ozAdminEmailContainer .delMoreIcon {
    display: none;
    cursor: pointer;
}

.ozAdminEmailContainer .ag-row-hover .delMoreIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
}

.oz-template-view-label {
    color: #99A0A8;
    font-size: 12px;
    font-weight: 400;
    line-Height: 14px
}

.oz-template-view-value {
    display: block;
    color: #212121;
    width: 390px;
    font-size: 14px;
    font-weight: 400;
    word-wrap: break-word;
    margin-top: 3px;
}

.oz-template-drawer-buttons {
    bottom: 15px;
    right: 10px;
    position: absolute;
    display: flex;
    justify-content: flex-end
}

.oz-emailtemplate-drawer-buttons {
    /* bottom: 15px; */
    right: 3px;
    position: absolute;
    display: flex;
    justify-content: flex-end
}

.oz-template-drawer-textdeild-container {
    width: 100%
}

.oz-template-drawer-title-container {
    display: flex;
    align-items: center;
    width: 100%
}

.oz-template-view-morevert-img {
    margin-right: 0.5rem;
    margin-Left: 0.625rem;
    align-self: center;
    cursor: pointer;
}

.oz-template-drawer-label-container {
    display: flex;
    justify-content: space-between
}

.oz-Template-view-lablel-container {
    display: block;
    margin-Right: 0.5rem;
    margin-Left: 15px;
    margin-Top: 15px;
    align-items: center
}