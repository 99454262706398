.oz-admin-holidays-container .delMoreIcon {
    display: none;
    font-size: large;
    cursor: pointer;
}

/* ag-grid adds ag-row-hover class on row hover */
.oz-admin-holidays-container .ag-row-hover .delMoreIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
}

.oz-admin-holidays-container {
    margin: 0px 8px 0px 8px;
    display: flex;
    flex-direction: column;
    width: 100%;
    color: #212121 !important;
}

.oz-admin-holidays-add-button.MuiButton-root {
    width: 160px;
    height: 30px;
    font-size: 0.875rem;
    font-weight: 400;
    border-radius: 8px;
    background-color: #3D8BF8;
}

.oz-admin-holidays-header {
    display: flex;
}

.oz-admin-holidays-campaign-chip.MuiChip-root {
    max-width: 160px;
    height: 24px;
    border-radius: 12px;
    font-size: 12px;
    min-width: 22px;
}

.oz-admin-holidays-form {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    width: 464px;
    gap: 16px;
    /* width: 29rem; */
    min-height: 200px;
}

.oz-admin-holidays-form-checkbox-container {
    margin-left: 3px;
}

.oz-admin-holidays-annually-recurring {
    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.oz-admin-holidays-annually-recurring-content {
    display: flex;
    gap: 16px;
    align-items: center;
}

.oz-admin-holidays-checkbox {
    margin-left: 36px;
    margin-top: 4px;
}

.oz-admin-holiday-form-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    /* background-color: rgba(0, 0, 0, 0.5); */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}