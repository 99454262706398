.oz-tb-otp-verify-lock-img {
    display: flex;
    justify-content: center;
    text-align: center;
    align-items: center;
    flex-direction: column;
}

.oz-tb-otp-verify-error-msg {
    color: red;
    font-size: 11px;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    margin: 5px 0px;
}

.oz-tb-otp-box {
    width: 20rem;
    margin-top: 20px;
}
