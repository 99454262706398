.oz-data-management-add-rules-title.MuiTypography-root {
    font-size: 14px;
    font-weight: 600;
    color: #546881;
    flex-grow: 1;
}

.oz-data-management-send-to-campaign-rule-title.MuiTypography-root {
    font-size: 16px;
    font-weight: 500;
    color: #47586E;
    flex-grow: 1;
}

.oz-data-management-send-to-campaign-rule-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    border: 1px solid #D6D9DC;
    border-radius: 8px;
    padding: 12px;
}

.oz-data-management-send-to-campaign-rule-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.oz-data-management-send-to-campaign-rule-header {
    display: flex;
    align-items: center;
    gap: 10px;
}

.oz-data-management-send-to-campaign-rule-footer {
    display: flex;
    gap: 0px;
    justify-content: flex-end;
}

.oz-data-management-send-to-campaign-rule-conditions-operator {
    margin-right: 10px;
}

.oz-data-management-send-to-campaign-rule-content-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.oz-data-management-send-to-campaign-rule-content {
    background-color: #F8F8F8;
    padding: 12px 16px;
}

.oz-data-management-send-to-campaign-rule-content .MuiTypography-subtitle1 {
    font-size: 14px;
    color: #546881;
    margin-bottom: 8px;
}

.oz-data-management-send-to-campaign-rule-priority-container {
    display: flex;
    gap: 10px;
    align-items: center;
    margin-top: 10px;
}

.oz-data-management-send-to-campaign-rule-priority-title.MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: #909DAD;
}

.oz-data-management-send-to-campaign-rule-priority-title-2.MuiTypography-root {
    font-size: 16px;
    font-weight: 400;
    color: #909DAD;
}

.oz-data-management-send-to-campaign-rule-priority-remove-button.MuiIconButton-root {
    margin-left: auto;
}

.oz-data-management-send-to-campaign-rule-condition-container {
    display: flex;
    align-items: center;
    width: 100%;
}

.oz-data-management-send-to-campaign-rule-condition-content {
    display: flex;
    flex-direction: column;
    overflow-x: auto;
    padding-bottom: 8px;
    width: 100%;
}

.oz-data-management-send-to-campaign-rule-condition-item {
    display: flex;
    align-items: center;
    width: 100%;
}

.oz-data-management-send-to-campaign-rule-condition-view-container {
    display: flex;
    align-items: center;
    width: 100%;
    background: #FAFAFA;
}

.oz-data-management-send-to-campaign-rule-view-content {
    display: flex;
    align-items: center;
    padding: 0px 12px;
    width: 100%;
    background-color: #FAFAFA;
    border-radius: 8px;
    min-height: 48px;
}

.oz-data-management-send-to-campaign-rule-view-condition {
    display: flex;
    align-items: center;
    border-radius: 8px;
    min-height: 48px;
}

.oz-data-management-send-to-campaign-rule-view-condition-chip {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
    width: 100%;
}

.oz-data-management-send-to-campaign-rule-view-condition-chip.MuiChip-root {
    max-width: 140px;
    width: fit-content;
    color: #FFC166;
    background-color: #FFFFFF;
    text-align: start;
    justify-content: flex-start;
}

.oz-data-management-send-to-campaign-rule-view-priority {
    display: flex;
    align-items: center;
    width: 25%;
    border-radius: 8px;
    min-height: 48px;
    margin-left: 20px;
}

.oz-data-management-send-to-campaign-rule-view-condition-list {
    display: flex;
    width: 93%;
    align-items: center;
    overflow-x: auto;
}

.oz-data-management-send-to-campaign-rule-condition-content::-webkit-scrollbar,
.oz-data-management-send-to-campaign-rule-view-condition-list::-webkit-scrollbar {
    height: 5px;
}

.oz-data-management-rule-condition-remove-button {
    filter: brightness(0) saturate(100%) invert(42%) sepia(64%) saturate(526%) hue-rotate(309deg) brightness(99%) contrast(101%);
}