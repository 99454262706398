.customTabs button.MuiTab-textColorPrimary {
    font-weight: 1000;
    font-size: 14px;
}

/* give height and overflow here */
.cardStyle {
    background-color: #f5f8fa !important;
    height: 100%;
}

.card-title {
    color: #545454 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
}

/* .MuiPaper-root{
    border: none ! important;
    box-shadow: none ! important;
} */

.customCard {
    border: 1px solid #d6d6d6;
    background-color: transparent !important;
    border-radius: 10px;
}

.custom-table-scroll {
    overflow: auto;
    height: 409px;
    --sb-track-color: transparent !important;
}

.custom-table-scroll::-webkit-scrollbar {
    width: var(--sb-size)
}

.MuiTabs-root {
    width: var(--sb-size)
}

.MuiTabs-scroller {
    overflow: auto;
}

.custom-table-scroll::-webkit-scrollbar-thumb {
    background-color: var(--sb-thumb-color);
    border-radius: 15px;
    border: 4px solid #fff;
    cursor: pointer;
}

.custom-table-scroll::-webkit-scrollbar-thumb:hover {
    background-color: #A9A9A9;
    /* Change color on hover */
}

.boldStyle {
    color: #616161 !important
}

.tableCell {
    border: none !important;
}

.tableCellTime {
    color: #b9b9b9 !important;
}