.oz-data-management-files-container {
    margin-top: 20px;
    height: calc(100vh - 200px);
    background-color: #FFFFFF;
    border-radius: 12px;
}

.oz-data-management-files-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #E6E5E6;
    height: 84px;
    padding: 0px 20px;
}

.oz-data-management-files-upload-button.MuiButton-root {
    background-color: #3D8BF8;
    border-radius: 8px;
    width: 122px;
    height: 36px;
    font-size: 12px;
    font-weight: 400;
}

.oz-data-management-files-upload-button .MuiButton-startIcon {
    margin-right: 2px;
}

.oz-data-management-files-no-files {
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100vh - 400px);
}

.oz-data-management-files-no-files-text.MuiTypography-root {
    font-size: 18px;
    font-weight: 400;
    color: #909DAD;
}

.oz-data-management-files-add-form-dialog.MuiPaper-root {
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    padding: 0px 30px;
    height: 550px;
    width: 645px;
    border-radius: 8px;
    box-shadow: 0px 4px 12px 0px hsla(0, 0%, 0%, 0.059);
}


.oz-data-management-files-add-form-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 64px;
}

.oz-data-management-files-add-form-buttons {
    display: flex;
    align-items: center;
    align-self: flex-end;
    justify-items: flex-end;
    margin-top: 20px;
    gap: 10px;
}

.oz-data-management-files-add-form-submit-button.MuiButton-root {
    background-color: #3D8BF8;
    border-radius: 8px;
    width: 98px;
    height: 36px;
    font-size: 12px;
    font-weight: 400;
}

.oz-data-management-files-add-form-cancel-button.MuiButton-root {
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 98px;
    height: 36px;
    font-size: 12px;
    font-weight: 400;
    color: #546881;
    border: 1px solid #546881;
}

.oz-data-management-files-add-form-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    height: 408px;
    overflow-y: auto;
}

.oz-data-management-files-add-form-content-item {
    display: flex;
    gap: 8px;
    width: 100%;
}

.oz-data-management-files-add-form-content-item-file-information {
    display: flex;
    flex-direction: column;
    gap: 2px;
    width: 100%;
}

.oz-data-management-files-add-form-content-item-file-name-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.oz-data-management-files-add-form-content-item-file-name.MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: #47586E;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.oz-data-management-files-add-form-content-item-file-close-button.MuiIconButton-root {
    padding: 4px;
    margin: 0px;
    min-width: 0px;
    min-height: 0px;
    display: flex;
    flex-direction: column;
    height: 18px;
    width: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.oz-data-management-files-add-form-content-item-file-size.MuiTypography-root {
    font-size: 10px;
    font-weight: 400;
    color: #47586E;
}

.oz-data-management-files-add-form-content-item-tags-container {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
}

.oz-data-management-files-add-form-content-item-title-tags.MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    color: #47586E;
}


.oz-data-management-files-add-form-content-item-title-tags-description.MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: #909DAD;
}

.oz-data-management-files-add-form-content-item-title-tags-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.oz-data-management-files-add-form-content-item-title-tags-add-icon {
    filter: brightness(0) saturate(100%) invert(38%) sepia(90%) saturate(2887%) hue-rotate(190deg) brightness(104%) contrast(100%);
}

.oz-data-management-files-add-form-content-item-title-tags-add-button.MuiIconButton-root {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    padding: 0px;
    margin: 0px;
}

.oz-data-management-files-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.oz-data-management-files-name.MuiTypography-root {
    color: #536580;
    font-size: 12px;
    font-weight: 400;
    margin-left: 14px;
}

.oz-data-management-files-list-container {
    padding: 0px 20px;
}