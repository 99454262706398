.oz-administration-container .gridMorevertIcon {
    display: none;
    font-size: large;
    cursor: pointer;
}

.oz-administration-container .ag-row-hover .gridMorevertIcon {
    display: inline;
    position: fixed;
    right: 0;
    margin-right: 30px;
}

.oz-administration-container .ag-cell-wrapper {
    width: 100% !important
}


.oz-administration-grid-cellrenderer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}