.oz-admin-integration-Idesk-page {
    width: 100%;
    height: 100%;
    margin: 20px;
    overflow-y: auto;
}

.oz-admin-integration-Idesk-IdeskName {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.13px;
    height: 28px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
    margin-top: 10px;
}

.oz-admin-integration-Idesk-form {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    width: 100%;
}

.oz-admin-integration-Idesk-form-positions {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: inherit;
}

.oz-admin-integration-Idesk-form-labels {
    font-size: 14px;
    font-weight: 400;
    color: #212121;
    margin-right: 30px;
    margin-left: 60px;
}

.oz-admin-integration-Idesk-form-textfield {
    min-width: 82.4% !important;
}


.oz-admin-integration-Idesk-form-secfield {
    margin-top: 30px;
}

.oz-admin-integration-Idesk-form-labels-secfield {
    margin-left: -50px;
}

.oz-admin-integration-Idesk-container {
    width: 100%;
    padding-right: 40px;
    margin-top: 70px;
    font-size: 14px;
}

.oz-admin-integration-Idesk-container table {
    width: 100%;
    border-left: 1px solid #E6E5E6;
    border-bottom: 1px solid #E6E5E6;
    font-size: 14px;
    border-collapse: collapse;
}

.oz-admin-integration-Idesk-container tr th {
    font-size: 13px;
    padding: 12px;
    border-top: 1px solid #E6E5E6;
    border-bottom: 1px solid #E6E5E6;
    text-align: left;
    background-color: #F0F1F2;
    font-weight: 500;
    color: "#000";
}

.oz-admin-integration-Idesk-container tr th:last-child {
    font-size: 13px;
    padding: 12px;
    border-top: 1px solid #E6E5E6;
    border-bottom: 1px solid #E6E5E6;
    text-align: left;
    background-color: #F0F1F2;
    font-weight: 500;
    color: "#000";
    border-right: 1px solid #E6E5E6;
}

.oz-admin-integration-Idesk-container tr:nth-child(odd) {
    background-color: #fff !important;
}

.oz-admin-integration-Idesk-container tr {
    background-color: #EDF0F280;
}

.oz-admin-integration-Idesk-container tr td {
    /* border-right: 1px solid #E6E5E6; */
    text-align: center;
    height: 70px !important;
    /* border-bottom: 1px solid #E6E5E6; */
    color: #212121;
    font-size: 14px;
}

.oz-admin-integration-Idesk-container tr td:last-child {
    /* border-right: 1px solid #E6E5E6; */
    text-align: center;
    height: 70px !important;
    /* border-bottom: 1px solid #E6E5E6; */
    color: #212121;
    border-right: 1px solid #E6E5E6;
}

.oz-admin-integration-Idesk-input-odd-row {
    font-size: 16px;
    border: none;
    background-color: #EDF0F280;
    width: 95%;
    padding: 12px 12px;
    height: 44px;
    border: 1px Solid #E6E5E6;
    border-width: 2px;
    box-shadow: none;
    /* max-width: 300px !important; */
}

.oz-admin-integration-Idesk-input-exceptionfields-odd-row {
    font-size: 16px;
    border: none;
    background-color: #EDF0F280;
    width: 80%;
    padding: 12px 12px;
    height: 44px;
    border: 1px Solid #E6E5E6;
    border-width: 2px;
    box-shadow: none;
    margin-top: 5px;
    margin-bottom: 5px;
    /* max-width: 252px !important; */
}

.oz-admin-integration-Idesk-input-even-row {
    font-size: 16px;
    border: none;
    background-color: #fff;
    width: 95%;
    padding: 12px 12px;
    height: 44px;
    border: 1px Solid #E6E5E6;
    border-width: 2px;
    box-shadow: none;
    /* max-width: 300px !important; */
}

.oz-admin-integration-Idesk-input-exceptionfields-even-row {
    font-size: 16px;
    border: none;
    background-color: #fff;
    width: 80%;
    padding: 12px 12px;
    height: 44px;
    border: 1px Solid #E6E5E6;
    border-width: 2px;
    box-shadow: none;
    margin-top: 5px;
    margin-bottom: 5px;
    /* max-width: 252px !important; */
}

.oz-admin-integration-Idesk-input-exceptionfields-even-row:focus {
    outline: 1px Solid #E6E5E6;
    border: 1px Solid #E6E5E6;
    height: 44px;
}

.oz-admin-integration-Idesk-input-exceptionfields-even-row::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #777;
}

.oz-admin-integration-Idesk-input-even-row:focus {
    outline: 1px Solid #E6E5E6;
    border: 1px Solid #E6E5E6;
    height: 44px;
}

.oz-admin-integration-Idesk-input-even-row::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #777;
}

.oz-admin-integration-Idesk-input-exceptionfields-odd-row:focus {
    outline: 1px Solid #E6E5E6;
    border: 1px Solid #E6E5E6;
    height: 44px;
}

.oz-admin-integration-Idesk-input-exceptionfields-odd-row::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #777;
}

.oz-admin-integration-Idesk-input-odd-row:focus {
    outline: 1px Solid #E6E5E6;
    border: 1px Solid #E6E5E6;
    height: 44px;
}

.oz-admin-integration-Idesk-input-odd-row::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #777;
}

.oz-admin-integration-Idesk-editableTable {
    display: flex !important;
    flex-direction: column !important;
    align-items: center !important;
    height: 100% !important
}

.oz-admin-integration-Idesk-errormessage {
    font-size: 12px;
    font-weight: 400;
    color: #EF6262 !important;
}


.oz-admin-integration-Idesk-card {
    width: 90%;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px Solid #E6E5E6;
    box-shadow: none;
    margin-bottom: 10px;
}

.oz-admin-integration-Idesk-card-big {
    width: 95%;
    margin-top: 10px;
    border-radius: 8px;
    border: 1px Solid #E6E5E6;
    box-shadow: none;
    margin-bottom: 10px;
    margin-left: 60px;
}

.oz-admin-integration-Idesk-card-headerContainer {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: -16px;
    min-height: 25px;
}

.oz-admin-integration-Idesk-card-headerContainer-simplecard {
    display: flex;
    flex-direction: row;
    align-items: center !important;
    height: 0px;
    margin-top: 8px;
}

.oz-admin-integration-Idesk-card-header {
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    display: flex;
    align-items: center;
    margin-left: 16px;
    margin-top: -8px;
    cursor: pointer;
}

.oz-admin-integration-Idesk-card-header-name {
    font-size: 16px;
    font-weight: 500;
    color: #212121;
    margin-top: 10px;
    margin-bottom: 10px;
}

.oz-admin-integration-Idesk-cardContents {
    background-color: #EDF0F280;
    margin-top: 25px;
}

.oz-admin-integration-Idesk-gridItem {
    display: flex !important;
    flex-direction: column !important;
    margin: 16px !important;
}

.oz-admin-integration-Idesk-label-header {
    font-size: 12px;
    font-weight: 500;
    color: #212121;
    width: 80px !important;
    margin-bottom: 5px;
}

.oz-admin-integration-Idesk-sameRows {
    display: flex;
    flex-direction: row;
}

.oz-admin-integration-Idesk-fieldType {
    font-size: 12px;
    font-weight: 400;
    color: #12344d
}

.oz-admin-integration-Idesk-fields {
    margin-top: 10px;
}

.oz-admin-integration-Idesk-singleLine-fields {
    min-height: 20px;
}

.oz-admin-integration-Idesk-doubleLine-fields {
    min-height: 80px;
}

.oz-admin-integration-Idesk-field-checkboxes {
    margin-top: -1px;
}

.oz-admin-integration-Idesk-card-input {
    font-size: 16px;
    border: none;
    background-color: #fff;
    width: 95%;
    padding: 12px 12px;
    height: 44px;
    border: 1px Solid #E6E5E6;
    border-width: 2px;
    box-shadow: none;
    /* max-width: 300px !important; */
}

.oz-admin-integration-Idesk-card-input:focus {
    outline: 1px Solid #E6E5E6;
    border: 1px Solid #E6E5E6;
    height: 44px;
}

.oz-admin-integration-Idesk-card-input::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #777;
}

.oz-admin-integration-Idesk-card-input-exceptionfields {
    font-size: 16px;
    border: none;
    background-color: #fff;
    width: 84%;
    padding: 12px 12px;
    height: 44px;
    border: 1px Solid #E6E5E6;
    border-width: 2px;
    box-shadow: none;
    margin-bottom: 10px;
    /* max-width: 252px !important; */
}

.oz-admin-integration-Idesk-card-input-exceptionfields:focus {
    outline: 1px Solid #E6E5E6;
    border: 1px Solid #E6E5E6;
    height: 44px;
}

.oz-admin-integration-Idesk-card-input-exceptionfields::placeholder {
    font-size: 14px;
    font-weight: 400;
    color: #777;
}