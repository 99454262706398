.ozcw-widget-footer {
    align-items: flex-end;
    background-color: white;
    padding: 10px;
    min-height: 88px;
    border-top: 0.5px solid #D6D9DC;
}

.ozcw-widget-footer-skillList {
    align-items: flex-end;
    background-color: white;
    padding: 10px;
    min-height: 88px;
    /* border-top: 0.5px solid #D6D9DC; */
}

.ozcw-widget-footer-input-container {
    gap: 2px;
    display: flex;
    overflow: hidden;
    align-items: center;
}

.ozcw-footer-to-container {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
}

.ozcw-footer-to-text.MuiTypography-root {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.41px;
    text-align: left;
    color: #000000;
}

.ozcw-new-message {
    border: 0;
    border-radius: 5px;
    resize: none;
    width: 90%
}

.ozcw-msg-input-search {
    height: 36px;
    border: none;
    font-size: 14px !important;
    min-width: 100px;

    input {
        border: none !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }

    .MuiOutlinedInput-input {
        height: 1px !important;
        margin-left: -12px !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root ::placeholder {
        color: #99A0A8 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        opacity: 1;
    }

}

.ozcw-search-menu {
    position: absolute;
    z-index: 100;
    background-color: white;
    max-height: 140px;
    overflow: auto;
    box-shadow: 0px 3px 4px 0px #00000026;
    bottom: 104px;
    left: 30px;
    width: 90%;
    overflow-y: auto;
    padding: 4px;
    border-radius: 8px;
}

.ozcw-search-menu-skillList-add{
    position: absolute;
    z-index: 100;
    background-color: white;
    max-height: 140px;
    overflow: auto;
    box-shadow: 0px 3px 4px 0px #00000026;
    bottom: 224px;
    left: 150px;
    width: 50%;
    overflow-y: auto;
    padding: 4px;
    border-radius: 8px;
}

.ozcw-search-menu-skillList-edit{
    position: absolute;
    z-index: 100;
    background-color: white;
    max-height: 140px;
    overflow: auto;
    box-shadow: 0px 3px 4px 0px #00000026;
    bottom: 160px;
    left: 150px;
    width: 50%;
    overflow-y: auto;
    padding: 4px;
    border-radius: 8px;
}

.small-scroll::-webkit-scrollbar {
    width: 4px;
    height: 4px;
}

.ozcw-msg-input {
    height: 36px;
    border: none;

    input {
        border: none !important;
    }

    .MuiOutlinedInput-notchedOutline {
        border: none !important;
    }

    .MuiOutlinedInput-input {
        height: 1px !important;
        margin-left: -12px !important;
    }

    .MuiInputBase-root.MuiOutlinedInput-root ::placeholder {
        color: #546881 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        opacity: 1;
    }

}

.ozcw-animate-list-item {
    animation: bottomToTop 0.4s;
}

@keyframes bottomToTop {
    0% {
        opacity: 0;
        transform: translate(0%, 35%);
    }

    50% {
        opacity: 0.4;
    }

    100% {
        opacity: 1;
        transform: translate(0%, 0%);
    }
}