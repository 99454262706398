.carousel-container {
	position: relative;
	width: 100%;
	background-color: #fff;
	border: 1px solid #e6e5e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
	border-radius: 12px !important;
	min-height: 411px;
	height: 100%;
}

.add-widget-placeholder {
	height: calc(100% - 80px);
}

.carousel-preview-card-content {
	height: 100% !important;
}

.carousel-widget-container {
	overflow: auto;
	height: calc(100% - 0px);
	padding: 8px 8px 0 8px;
}

.add-widget-btn.MuiIconButton-root {
	position: absolute;
	right: 0;
	bottom: 0;
}

.add-widget-btn.MuiIconButton-root svg {
	font-size: 18px;
}

.card-content {
	height: 328px;
	display: flex;
	align-items: center;
	justify-content: center;
	border-bottom: 1px solid #e6e5e6;
}

.link-text {
	cursor: pointer;
	color: #3d8bf8;
}