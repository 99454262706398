.filter-select-links {
	margin: 18px 0;
}

.filter-select-links .MuiLink-root {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #3d8bf8;
	margin-right: 24px;
}

.filter-select-links .MuiLink-root:last-child {
	margin-right: 0;
}

.custom-label .MuiTypography-root {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #212121;
}

.custom-label .MuiCheckbox-root {
	padding: 0 9px;
}

.filter-checkbox-col {
	padding-top: 5px !important;
}

.custom-search-dropdown .MuiPaper-root {
	border: 1px solid #e6e5e6 !important;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
	border-radius: 12px !important;
	margin-top: 4px;
	width: 100px;
}

.filter-accordion-items-container {
	width: 100%;
	margin-bottom: 10px;
	padding: 10px 0;
	overflow-y: auto;
}

.filter-items-footer {
	width: 100%;
	height: auto;
	padding: 1rem;
	border-top: 1px rgba(0, 0, 0, 0.04) solid;
}

.filter-items-footer button {
	width: 100%;
	background-color: #3d8bf8;
	border-radius: 8px;
}

.filter-items-footer .footer-line {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	font-size: 10px;
	line-height: 12px;
	color: #99a0a8;
	margin: 12px 0;
}

.filter-items-footer .footer-line img {
	margin-right: 10px;
}

.drawer-dropdown-container {
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	justify-content: space-between;
	margin-top: 1rem;
	max-height: calc(100% - 100px - 1rem);
}
