.Ivr {
  --ag-odd-row-background-color: #f2f2f2;
}

.oz-ivr-container .gridMorevertIcon {
  display: none;
  font-size: large;
  cursor: pointer;
}

.oz-ivr-container .ag-row-hover .gridMorevertIcon {
  display: inline;
  position: fixed;
  right: 0;
  margin-right: 10px;
}

.oz-ivr-container .ag-cell-wrapper {
  width: 100% !important
}

.oz-Ivr-View-Header {
  font-weight: 400;
  font-size: 12px;
  color: #99A0A8;
}

.oz-Ivr-View-Value {
  display: block;
  font-weight: 400;
  font-size: 14px;
  color: #212121;
}

.oz-Ivr-View-Checkbox {
  background: #FFFFFF;
  border: 1px solid #99A0A8;
  border-radius: 4px;
  size: small;
}

.oz-ivr-flowName-colour {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #3D8BF8;
}

.oz-ivr-container {
  margin-left: 8px;
  margin-right: 9px;
}

.oz-Ivr-View-AppUrl-Container {
  display: block;
  margin-left: 15px;
  margin-top: 10px;
  width: 100%
}

.oz-ivr-drawer-buttons-container {
  bottom: 15px;
  right: 15px;
  position: absolute;
  display: flex;
  justify-content: flex-end,
}

.oz-ivr-drawer-title-container {
  display: flex;
  align-items: center;
}

.oz-ivr-view-morevereticon {
  margin-right: 0.5rem;
  margin-left: 0.625rem;
  align-self: center;
  cursor: pointer;
}

.oz-ivr-grid-cellrenderer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}