.search-drag-drop {
	width: calc(100% - 20px);
	height: 36px;
	background: #f6f9fd;
	border: 1px dashed #3d8bf8;
	border-radius: 6px;
	margin: 6px 10px;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
	display: flex;
	align-items: center;
	justify-content: center;
	position: relative;
}

.search-drag-drop span {
	color: #99a0a8;
	margin-left: 5px;
}

.card-table-container {
	width: 100%;
	height: inherit !important;
	max-height: calc(100% - 120px) !important;
	overflow: hidden;
}

.changed-card-table-container {
	max-height: 510px !important;
	overflow-y: scroll !important;
}

.custom-table-paper-height {
	height: 100% !important;
}

.custom-table-maxHeight {
	height: inherit !important;
	overflow-y: auto;
}

.draggable-table-cell {
	cursor: grab;
}

.custom-icon-cell {
	display: flex !important;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	min-height: 100%;
}

.threshold-bg {
	background-color: rgba(242, 194, 194, 0.3);
}

span.icon-row {
	width: 100%;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 25px;
}

.icon-row .icon-col {
	width: 100%;
	min-width: 45px;
	height: auto;
	display: flex;
	align-items: center;
	justify-content: center;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #536580;
}

.icon-col button,
.icon-col-sm button {
	padding: 0;
}

.icon-col button[disabled] img {
	opacity: 0.7;
}

span.icon-row span.icon-col-sm {
	width: 20px;
	min-width: auto;
}

.expand-icon {
	margin-left: 5px;
}

.bargein-icon {
	margin-left: 15px;
}