.otp-input {
    width: 44px;
    height: 44px;
    border: 1px solid #D6D6D7;
    border-radius: 8px;
    text-align: center;
    font-size: 32px;
    line-height: 1;
    margin-left: 20px;


}

.countdown-text {
    margin-top: 12px;

}

.otp-input-group {
    margin-left: 15px;
    margin-top: 16px;
    margin-bottom: 40px;

}