.customCard_dynamicWidget {
    width: 100%;
    border: 1px solid #e6e5e6;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
    border-radius: 12px !important;
    margin-bottom: 10px !important;
}

.dynamic-widget-cell-center {
    text-align: center;
}

.dynamic-widget-header-center .ag-header-cell-label {
    justify-content: center;
    text-align: center;
}