.widget-row {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
}

.widget-row:last-child {
    margin-bottom: 0;
}

.widget-row {
    width: 100%;
    height: auto;
    display: flex;
    align-items: flex-start;
    gap: 20px;
}

.widget-inner-card {
    width: 100%;
    height: auto;
    min-height: 125px;
    background-color: #ffffff;
    border: 1px solid #e6e5e6;
    border-radius: 12px;
    display: flex;
    align-items: center;
}

.widget-values-container-non-register {
    padding-left: 30px;
}

.widget-values-container {
    padding-left: 30px;
}

.widget-values-container.widget-values-inline {
    padding-left: 0;
}

.widget-caption {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #99a0a8;
    margin-bottom: 8px;
}

.widget-values-sm .widget-caption {
    font-size: 12px;
    line-height: 14px;
    margin-bottom: 0;
}

.widget-values {
    display: flex;
    align-items: center;
}

.widget-values .widget-value {
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    color: #212121;
    margin-right: 5px;
}

.widget-values-sm .widget-values .widget-value {
    font-size: 14px;
    line-height: 16px;
}

.widget-values .widget-value.widget-value-md-pulse {
    font-size: 20px; /*this is changed*/
    line-height: 21px;
    font-weight: 600; /*this is extra*/
    margin-top: 8px;  /*this is extra*/
    padding-left: 15px;
}

.widget-values .widget-value-update {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #159d3b;
}

.widget-values .widget-value-update.red {
    color: #e65b5c;
}

.widget-values-inline {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 12px;
}

.widget-values-row {
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.widget-values-row .widget-caption {
    width: fit-content;
    min-width: 115px;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #99a0a8;
    margin: 0 0;
}

.widget-values-row .widget-value {
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
}

.filter-container {
    /* width: fit-content;
	max-width: calc(100% - 20px); */
    height: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.filter-container::-webkit-scrollbar {
    display: none;
    /* Safari and Chrome */
}

.filter-container .MuiChip-colorPrimary {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #536580;
    height: 22px;
    background: rgba(0, 142, 255, 0.1);
    border: 1px solid #008eff;
    border-radius: 15px;
}

.filter-outer {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.customCardPulse {
    width: 100%;
    border: 1px solid #e6e5e6;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
    border-radius: 12px !important;
    min-height: 250px;
    height: inherit !important;
    max-height: 108% !important;
}

.filter-outer .filter-remove {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #3d8bf8;
    cursor: pointer;
    margin-left: 12px;
}

.card-header.border-none {
    border-bottom: none;
}