.card-title{
    color: #545454 !important;
    font-weight: 500 !important;
    font-size: 20px !important;
}

.customCard {
    align-items: center;
    width: 100%;
	border: 1px solid #e6e5e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
	border-radius: 12px !important;
	min-height: 409px;
	height: inherit !important;
	max-height: 108% !important;
}

.card-header {
    width: 100%;
    height: 45px;
    padding: 0 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid #e6e5e6;
}

.card-header .card-actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}