#mode-select.MuiSelect-select.MuiSelect-outlined {
	padding: 4px 4px 4px 16px;
	font-size: 14px;
}

.disposition-toggle {
	margin-left: 8px;
	max-height: 30px;
}

.disposition-toggle .MuiToggleButtonGroup-grouped {
	padding: 4px 11px;
}

.disposition-toggle .MuiToggleButton-root {
	width: 32px;
}

.disposition-toggle .MuiToggleButton-root.Mui-selected {
	border: 1px solid #008eff !important;
	background-color: white;
}

.disposition-toggle .MuiToggleButton-root {
	border: 1px solid #dae1e6;
	background-color: #f5f8fa;
	margin-left: 0 !important;
}

.popover-accordian-summary {
	padding: 0 8px !important;
}

.popover-accordian-summary .MuiAccordionSummary-content>div {
	width: 100%;
}

.popover-accordion {
	box-shadow: none;
}

.popover-accordion .MuiAccordionDetails-root {
	padding: 8px;
}

.popover-accordian-details {
	background-color: #f2f2f2;
	border-radius: 12px;
	padding: 1px 16px 16px 16px;
	margin-bottom: 8px;
}

.disposition-count {
	color: #008eff;
	cursor: pointer;
}

.menu-text-actions {
	font-weight: bolder !important;
	font-size: 12px !important;
	line-height: 14px !important;
	color: #212121 !important;
}

.disposition-count svg {
	position: relative;
	top: 2px;
}