.ozcw-carousel-ref-container {
    width: 100%;
}

.ozcw-carousel-image {
    border-radius: 4px;
    background-size: cover;
    user-select: none;
}

.ozcw-carousel-container {
    padding: 8px;
    overflow: hidden;
    position: relative;
}

.ozcw-carousel-arrow.MuiIconButton-root {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1000;
    width: 40px;
    height: 40px;
    box-shadow: 0px 0px 7px #e0e0e0;
    background: white;
}

.ozcw-carousel-arrow.MuiIconButton-root:hover {
    background-color: rgba(255, 255, 255, .8);
}

.ozcw-carousel-arrow.MuiIconButton-root.Mui-disabled {
    background: #9e9e9e;
}

.ozcw-carousel-left-arrow {
    left: 0;
}

.ozcw-carousel-right-arrow {
    right: 0;
}

.ozcw-carousel-container-cards {
    display: flex;
    flex-direction: row;
    position: relative;
}

.ozcw-carousel-container-card.MuiCard-root {
    border-radius: 8px;
    display: flex;
    flex-direction: column;
}

.ozcw-carousel-container-cards-transform-provider {
    display: flex;
    transition: transform 0.6s;
}

.ozcw-carousel-container-card-item {
    border-radius: 6px;
    max-width: 246px;
    overflow-y: hidden;
    max-height: 52vh;
    padding: 0px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ozcw-carousel-container-card-item-content.MuiCardContent-root {
    padding: 4px;
    flex-grow: 1;
}