.tab-container {
	background: #edf0f2;
	border: 1px solid #d4d7d9;
	border-radius: 8px;
	height: 36px;
}
.oz-tabs {
	max-height: 36px !important;
	min-height: 36px !important;
}
.oz-tabs .MuiTabs-flexContainer {
	max-height: 100%;
}
.oz-tabs .MuiTabs-indicator {
	display: none;
}
.oz-tab .MuiTab-iconWrapper {
	font-size: 20px;
	color: #99a0a8;
}
.oz-tab.MuiTab-textColorPrimary:not(:first-child) {
	border-left: 1px solid #d4d7d9;
}
.oz-tab.MuiTab-textColorPrimary {
	padding: 0 8px;
	max-height: 36px;
	min-width: 120px;
	min-height: 36px;
}
.oz-tab.Mui-selected {
	background: #d4d7d9;
	border: none;
	border-radius: 8px 0px 0px 8px;
	color: #212121 !important;
}

.no-padding.MuiTab-textColorPrimary {
	padding: 0;
	max-height: 36px;
	max-width: 36px !important;
	width: 36px;
	min-height: 36px;
	min-width: 36px;
}

.toolBarAvatar {
	width: 40px !important;
	height: 40px !important;
	font-size: 14px !important;
	color: #212121 !important;
	margin-top: -5px;
	box-sizing: border-box;
	border: 1px solid #75FB91;
	cursor: pointer !important;
	/* margin-left: 5px; */
}