.ozcw-widget-launcher {
    margin-top: 5px;
    align-self: flex-end;
}

.ozcw-launcher-close-icon.MuiFab-root {
    background-color: #FFFFFF;
}

.ozcw-launcher-close-icon.MuiFab-root:hover {
    background-color: #FFFFFF;
}