.oz-tb-audio-player-container {
    display: flex;
    flex-direction: row;
    border-radius: 5px !important;
    background: #3D8BF8;
    width: 100%;
    height: 44px !important;
}

.oz-tb-audio-player-waveform-parent-container {
    width: 82% !important;
    margin-left: 5px !important;
    align-self: center !important;
}

/* .oz-tb-audio-player-waveform-container {
    
} */

.ob-tb-audio-player-current-time {
    width: 24.93px;
    height: 12px;
    font-weight: 300;
    font-size: 13px;
    color: #FFFFFF;
    align-self: center !important;
    margin-left: 20px !important;
    margin-right: 20px !important;
    margin-bottom: 5px;
}

.oz-tb-audio-player-audio-buttons.MuiIconButton-root {
    margin-left: 5px;
}