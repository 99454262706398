.ozAdminIntegrationContainer .disconnectButton {
    color: #008DFF;
    font-size: 14px;
    line-height: 16.41px;
    font-weight: 400;
    margin-bottom: 18px;
    margin-right: 15px;
    border-radius: 0px;
    border-bottom: 1px solid #E6E5E6;
}

.ozAdminIntegrationContainer {
    margin-left: 8px;
}

.ozAdminIntegrationContainer .disconnectButtonPosition {
    display: flex;
    flex-grow: 1;
    width: 100%;
    justify-content: space-between;
}

.ozAdminIntegrationCardContainer {
    margin-left: 8px;
    margin-right: 9px;
}

.ozAdminIntegrationCardContainer .integrationNameLabel {
    /* color: #7C878B; */
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    padding-left: 8px;
    cursor: pointer;
}

.ozAdminIntegrationCardContainer .integrationNameLabelWithoutCursor {
    /* color: #7C878B; */
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    padding-left: 8px;
    /* cursor: pointer; */
}

.ozAdminSubIntegrationContainer {
    margin-left: -25px;
    margin-top: -16px;
}

.ozAdminSubIntegrationContainer .editButton {
    display: flex;
    position: inherit !important;
    right: 0;
    z-index: 1;
    cursor: pointer;
    color: "#008DFF";
    font-weight: 400;
    font-size: 14px;
    margin-right: 66px;
}

.ozAdminSubIntegrationContainer .discardButton {
    color: #008DFF;
    font-size: 14px;
    font-weight: 400;
}

.ozAdminSubIntegrationContainer .saveButton {
    background-color: "#3D8BF8";
    width: 76px;
    height: 30px;
    border-radius: 8px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 23px;
    padding-right: 23px;
    margin-top: 3.7px;
    font-weight: 400;
    cursor: pointer;
}

.ozAdminSubIntegrationContainer .notAllowSaveButton {
    background-color: "#3D8BF8";
    width: 76px;
    height: 30px;
    border-radius: 8px;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 23px;
    padding-right: 23px;
    margin-top: 3.7px;
    font-weight: 400;
    cursor: not-allowed;
}

.ozAdminSubIntegrationContainer .generateClick2CallButton {
    display: block;
    position: fixed;
    right: 0;
    z-index: 1;
    cursor: pointer;
    color: "#008DFF";
    font-weight: 400;
    font-size: 14px;
    margin-right: 30px;
}

.ozAdminSubIntegrationContainer .editButtonPosition {
    display: flex;
    width: 100%;
    justify-content: space-between;
}

.ozAdminIntegrationContainer .oz-page-header {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    width: 213px;
    height: 14px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
    cursor: pointer;
}

.ozAdminIntegrationContainer .oz-page-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.13px;
    width: 151px;
    height: 28px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}

.ozAdminIntegrationCardContainer .oz-page-header {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    width: 230px;
    height: 14px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
    cursor: pointer;
}

.ozAdminIntegrationCardContainer .oz-page-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.13px;
    width: 130px;
    height: 28px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}

.ozAdminSubIntegrationContainer .ag-root-wrapper {
    border-bottom-left-radius: 12px !important;
    border-top-left-radius: 12px !important;
    border-bottom-right-radius: 12px !important;
    border-top-right-radius: 12px !important;
    border: solid 1px #E6E5E6 !important;
    border-top: none !important;
    border-radius: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
}

.ozAdminIntegrationCardContainer .switch {
    margin-top: 8px !important;
}

/* .config-dropzone-text { */
/* font-size: 0.875rem !important; */
/* or something */
/* color: #99A0A8 !important;
    position: absolute;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1rem !important;
    width: 12.313rem !important;
    height: 1rem !important;
    margin-left: -45px !important;
    margin-top: 14px !important; */
/* left: 8.6rem; */
/* top: 3rem; */
/* display: none; */
/* } */

.config-dropzone-text {
    font-size: 0.875rem !important;
    color: #99A0A8 !important;
    position: absolute;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    height: 1rem !important;
    margin-left: 10px !important;
    margin-top: 14px !important;
    /* display: none; */
}

.config-dropzone-no-text {
    display: none !important;
}

.config-drop-zone-area {
    background: #FFFFFF !important;
    border: 0.063rem solid #D6D6D7 !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    min-height: 2.85rem !important;
}