.card-search {
	width: 100%;
	min-width: 240px;
	height: 30px;
	background-color: #ffffff;
	border: 1px solid #d6d6d7;
	border-radius: 8px;
	margin-right: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px;
}

.card-search-widget {
	width: 100%;
	min-width: 240px;
	height: 30px;
	background-color: #ffffff;
	border: 1px solid #d6d6d7;
	border-radius: 0px 8px 8px 0px;
	margin-right: 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 8px;
}

.card-search-widget input {
	width: 100%;
	outline: none;
	border: none;
	color: #212121;
}

.card-search input {
	width: 100%;
	outline: none;
	border: none;
	color: #212121;
}