.date-tabs-container .from-button {
	font-weight: 400;
	font-size: 14px;
	width: 156px;
}
.date-tabs-container .to-button {
	font-weight: 400;
	font-size: 14px;
	width: 170px;
}
.tab-context .css-ypiqx9-MuiDialogContent-root {
	padding: 0px;
}
.dialog-content .css-13xfq8m-MuiTabPanel-root {
	padding: 0px;
}
.css-sf5t6v-PrivatePickersSlideTransition-root-MuiDayPicker-slideTransition {
	min-height: 220px;
}
.buttons-container {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
}
