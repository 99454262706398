.ozcw-widget-header {    
    display: flex;
    padding: 0px 0px 0px 10px;
    height: 45px;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0px 4px 4px 0px #0000001A;
    background-color: #FFFFFF;
}

.ozcw-widget-title.MuiTypography-root {
    color: #1D242D;   
    text-align: left;     
    font-size: 18px;
    font-weight: 500;
}
