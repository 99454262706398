.tooltipHeader {
    float: right;
    font-size: 0.6rem !important;
}

.popover-accordian-summary-box {
    color: #212121;
    font-weight: 500;
    font-size: 14px;
}

.popover-accordian-summary-box-title {
    font-size: 16px;
    font-weight: 600;
}

.popover-accordian-summary-subBox {
    color: #212121;
    font-size: 14px;
    font-weight: 400;
    margin-left: 5px;
    display: flex;
    margin-top: 10px;
}

.popover-accordian-summary-subBox-title {
    flex-grow: 1;
    margin-left: 13px;
    margin-right: 13px;
}

.popover-accordian-summary-subBox-value {
    font-size: 16px;
    font-weight: 600;
    margin-top: -5px
}

.linear-progress {
    margin-top: -6px;
}

.popover-accordian-summary-box-total {
    text-align: center;
    margin-bottom: 4px;
}