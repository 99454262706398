.dropzone-text {
    font-size: 0.875rem !important;
    /* or something */
    color: #99A0A8 !important;
    position: absolute;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1rem !important;
    height: 1rem !important;
    left: 8rem;
    top: 1rem;
}

.drop-zone-area {
    background: #FFFFFF !important;
    border: 0.063rem solid #D6D6D7 !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    /* height: 4rem !important; */
    min-height: 5.375rem !important;
    max-height: 134px !important;
}

.oz-note {
    background: #F4F4F4 !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    padding: 10px;
    margin-left: 18px;
    font-weight: 400;
    margin-top: 20px;
    padding-bottom: 20px;
}

.css-1qkoffw {
    justify-content: center !important;
}