.grid-item {
	position: relative;
	box-shadow: 0px 4px 12px rgb(0 0 0 / 4%);
}

.delete-icon {
	position: absolute;
	right: -10px;
	top: -10px;
	cursor: pointer;
	z-index: 10;
}

.react-grid-layout {
	min-height: 350px !important;
}

.widget-container {
	height: inherit !important;
	/* margin-top: 10% !important; */
	/* overflow: hidden; */
}

.react-grid-item.react-grid-placeholder {
	background: rgba(61, 139, 248, 0.3) !important;
	border: 1px dashed #3d8bf8;
	border-radius: 12px;
}

#dashboard-name {
	padding: 10px;
}