.ozAdminManageNumbers .delMoreIcon {
    display: none;
    font-size: large;
    cursor: pointer;
}

/* ag-grid adds ag-row-hover class on row hover */
.ozAdminManageNumbers .ag-row-hover .delMoreIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
}

.oz-manage-numbers-morevert-column-render {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.oz-manage-numbers-container {
    margin-left: 5px;
    margin-right: 5px;
}

.oz-manage-numbers-add-button-container {
    display: flex;
    align-items: center;
}

.oz-manage-numbers-tabs-container {
    display: flex;
    height: 1.875rem;
}

.oz-manage-numbers-tabs-divider-container {
    flex-grow: 1;
    align-self: flex-end;
}

.oz-add-phone-number-form-container {
    width: 29rem;
}

.oz-add-phone-number-form-add-container {
    padding-top: 0px;
    padding-left: 0px;
    padding-right: 0px;
}

.oz-add-phone-number-form-edit-container {
    padding-top: 1.25rem;
    padding-left: 0.625rem;
    padding-right: 1rem;
}

.oz-add-phone-number-form-edit-button-container {
    bottom: 15px;
    left: 295px;
    position: absolute;
    display: flex;
    justify-content: flex-end;
}

.oz-add-phone-number-form-drawer-header-container {
    display: flex;
    width: 100%;
    align-items: center;
}

.oz-add-phone-number-form-drawer-header-view-container {
    padding-left: 0px;
}

.oz-add-phone-number-form-drawer-header-edit-container {
    padding-left: 12px;
}

.oz-add-phone-number-form-drawer-header-morevert {
    margin-right: 0.5rem;
    margin-left: 0.625rem;
    cursor: pointer;
}

.oz-add-phone-number-form-drawer-header-items-container {
    margin-top: 20px;
}

.oz-add-phone-number-form-drawer-header-items-close-icon-container {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

.oz-add-phone-number-form-drawer-edit-button-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 50px;
    padding-bottom: 10px;
}

.oz-add-block-group-checkbox-table-container {
    display: flex;
    margin-top: 20px;
    margin-bottom: 4px;
    justify-content: space-between;
    height: 20px;
}

.oz-add-block-group-form-view-container {
    width: 435px;
    margin-top: 1.5rem;
    margin-left: 0.625rem;
    margin-right: 0rem;
    padding-right: 15.5px;
}

.oz-add-block-group-form-view-inner-container {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 4px;
    justify-content: space-between;
}

.oz-block-group-upload-file-image {
    margin-top: 1rem;
    display: flex;
    justify-content: left !important;
    position: absolute;
    left: 4rem;
}

.oz-checkbox{
    position: relative;
    left: -5.5rem;
    top: 2.5rem;
    margin-bottom: -2rem;
}

.oz-grid-group{
    padding: 15px;
    margin: -5px;
    margin-top: 1px;
    box-sizing: border-box;
    background: #FFFFFF !important;
    border: 0.063rem solid #D6D6D7 !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
}

.ag-header-cell-comp-wrapper{
    justify-content: end;
}

.oz-add-block-group-form-view-container {
    padding-left: 0rem;
    padding-right: 0px;
}

.oz-add-block-group-form-edit-container {
    padding-top: 2.25rem;
    padding-left: 0.625rem;
    padding-right: 15.5px;
}

.oz-add-block-group-form-edit-button-container {
    bottom: 10px;
    left: 230px;
    position: absolute;
    display: flex;
    justify-content: flex-end;
}

.oz-managenumbers-drawer-title-heading {
    max-width: 375px;
    text-overflow: ellipsis !important;
    white-space: nowrap !important;
    overflow: hidden !important;
}

.oz-block-group-edit-button{
    position: absolute !important;
    right: 0 !important;
    margin-right: -18% !important;
}