.custom-icon-cell {
	display: flex !important;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
}

.custom-table .warning {
	background: rgb(242, 194, 194, 0.3);
}

.total-background {
	background-color: #d6d6d7 !important;
}

.badge {
	display: inline-block;
	background: #e5f4ff;
	border-radius: 30px;
	margin-left: 10px;
	color: #3296fb;
	padding: 1px 6px;
}

.login-color {
	color: #3296fb;
}

.logout-color {
	color: #536580;
}

.primary-color {
	color: #008dff;
}

.aux-color {
	color: #e0a751;
}

.ready-color {
	color: #4f9f52;
}

.busy-color {
	color: #e65b5c;
}

.acw-color {
	color: #e0a751;
}

.calling-color {
	color: #3296fb;
}

.exception-color {
	color: #E65B5C
}

.pause-color {
	color: #536580
}

.idle-color {
	color: #0b0bff;
}

.bold-font {
	font-weight: bold;
}

.MuiTableCell-root.MuiTableCell-head {
	vertical-align: middle;
}

.custom-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root span {
	font-weight: 500;
	color: #000000;
}

.sort-icon {
	margin-left: 6px;
}

.custom-table .css-1vweko9-MuiSvgIcon-root-MuiTableSortLabel-icon {
	display: none;
}

.card-table-container .custom-table {
	width: inherit !important;
}

.idle-color {
	color: #0b0bff;
}