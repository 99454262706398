.admin-settings-text-font {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #212121;
}


.admin-settings-text-font {
    padding-left: 24px;
    padding-top: 16px;
    font-family: 'Roboto';
    font-style: 'normal';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    padding-bottom: 16px;
}

.admin-settings-input-text {

    padding-right: 24px;
    padding-top: 16px;
    font-family: 'Roboto';
    font-style: 'normal';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    text-align: right;
}

.dialogImageCss {
    height: 128px;
    width: 128px;
    max-width: 128px;
    max-height: 128px;
    border-radius: 50%;
    margin-right: -45px;
}

.defaultImagecss {
    width: 180px;
    height: 180px;
    max-width: 180px;
    max-height: 180px;
    border-radius: 50%;
}


.editImagecss {
    height: 180px;
    width: 180px;
    max-height: 180px;
    max-height: 180px;
    border-radius: 50%;
}

.adminViewLabel {
    color: '#7C878B'
}

.adminEditLabel {
    color: '#000000'
}

.hide {
    display: none;
}


.settingField:hover .hide {
    display: block;

}

.settingField .dontFlicker {
    pointer-events: none;
}

.ozAdminSettings {
    margin-left: 8px;
}