.oz-admin-Skill-container .gridMorevertIcon {
    display: none;
    font-size: large;
    cursor: pointer;
}

.oz-admin-Skill-container .ag-row-hover .gridMorevertIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
}

.oz-admin-Skill-container {
    margin-left: 8px;
    margin-right: 8px;
}

.oz-skill-anotherlevel-container {
    display: flex
}

.oz-skill-view-lablel-container {
    display: block;
    margin-Right: 0.5rem;
    margin-Left: 0.625rem;
    margin-Top: 15px;
    align-items: center
}

.oz-skill-view-lablel-container-skilldetails {
    display: block;
    margin-Right: 0.5rem;
    margin-Left: 0.625rem;
    margin-Top: 15px;
    align-items: center;
    max-width: 400px;
    word-break: break-all;
}

.oz-skill-view-agents-container {
    display: flex;
    align-items: center;
    height: 38px;
    border: 1px solid #D6D6D7;
    border-radius: 8px 8px 0px 0px
}

.oz-skill-basicinfo-container {
    display: flex;
    justify-content: space-between
}

.oz-skill-basicinfo-buttons-container {
    display: flex;
    margin-left: 304px;
    margin-Top: 60px;
}

.oz-skill-view-drawer-title-container {
    display: flex;
    align-items: center
}

.oz-skill-grid-columns-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%
}

.oz-skill-anotherlevel-img {
    padding-right: 6px;
    padding-bottom: 3px
}

.oz-skill-view-editFeature-container {
    display: flex;
    margin-Right: 0.5rem;
    margin-Left: 0.625rem;
    margin-Top: 15px;
    align-items: center;
    justify-content: space-between;
}

.oz-skill-view-phonenumbers-container {
    display: flex;
    gap: 16px;
    flex-Wrap: wrap
}

.oz-skill-view-calldropaction-container {
    display: flex;
    margin-left: 2px
}

.oz-skill-view-moreverticon-img {
    margin-right: 0.5rem;
    margin-left: 0.625rem;
    align-self: center;
    cursor: pointer;
}

.oz-skill-Location-container {
    justify-content: space-between;
    display: flex;
    margin-left: 10px;
    margin-top: 10px
}