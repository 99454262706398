.content {
	flex-grow: 1;
	padding: 12px 12px 0px 102px;
	height: auto;
	min-height: 100%;
	background: #f6fafd;
}

.toolbar {
	margin-top: 75px;
}
