.header-border{
    border-bottom: 1px solid #E6E5E6;
}
.today-container{
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: #464646;
    background-color: #F6F9FD;
    padding: 3px 0px 3px 20px;
}
.notification-heading{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    padding-left: 4px;
}
.notification-time{
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #646464;
}
.notification-message{
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    margin-top: 8px;
    color: #373737;
    margin-bottom: 8px;
}
.notification-warning{
    color: #E65B5C;
}
.notification-success{
    color: #4F9F52;
}
.display-cursor{
    cursor: pointer;
}
.threshold-settings-container{
    padding: 16px 26.5px 16px 20px;
    border-bottom: 1px solid #E6E5E6;
}
.threshold-style{
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 16px;
    color: #373737;
    padding-left: 12px;
}
.all-container-shadow{
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.08);
    z-index: 10;
}
.threshold-each-container{
    border: none;
    background-color: #F6F7F8;
    
}
.each-container-heading{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    padding-left: 10px;
}
.see-all{
    cursor: pointer;
    color: #008DFF;
    font-family: 'Roboto';
    font-weight: 400;
}
.see-all-container{
    border-bottom: 1px solid #E6E5E6;
    padding-top: 0px;
}
.notification-bubble{
    width: 4px;
    height: 4px;
    background-color: #008EFF;
    border-radius: 4px;
    position: absolute;
    top: 20px;
    left: 12px;
}
.each-notification-container{
    border-bottom: 1px solid #E6E5E6;
    position: relative;
    padding: 10px 20px 10px 20px;
}