#mode-select.MuiSelect-select.MuiSelect-outlined {
	padding: 4px 4px 4px 16px;
	font-size: 14px;
}

.customCard_hourlyCallSummary {
	width: 100%;
	border: 1px solid #e6e5e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
	border-radius: 12px !important;
	min-height: 409px;
	height: inherit !important;
	max-height: 108% !important;
}

.tooltip-card {
    border: 1px solid #ccc;
    padding: 10px;
    border-radius: 5px;
    background-color: white !important; /* Set background color to white */
}

