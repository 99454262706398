.listItem {
	text-align: center;
	padding: 10px 6px;
	cursor: pointer;
	color: #b6b6b6;
	margin-bottom: 20px;
	text-align: center;
	border-radius: 4px;
}

.listItem a,
a {
	text-decoration: none;
}

.listItem svg {
	stroke: #99a0a8;
}

.listItemText {
	margin: 0;
	font-size: 10px;
	line-height: 1;
}

.listItem.active {
	background-color: #e3ecfd;
}

.listItem.active .listItemText {
	font-weight: 600;
	color: #3d8bf8;
}

.listItem.active svg {
	stroke: #3d8bf8;
}