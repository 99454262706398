.customCard_Performace {
    width: 100%;
    border: 1px solid #e6e5e6;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
    border-radius: 12px !important;
    margin-bottom: 10px !important;
    min-height: 409px;
    height: inherit !important;
    max-height: calc(100% - 5px) !important;
    overflow-y: scroll !important;
}