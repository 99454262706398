.ozcw-widget-container {
    margin: 10px;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    margin: 0px 4px 4px 0px;
    z-index: 1;
    min-width: 0;
}

.ozcw-widget-container-expanded {
    width: 27rem;
}