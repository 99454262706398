.keys {
    text-align: 'center'

}

/* .switch{
    background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.15);
        border-radius: 10px;
} */

.admin-settings-text-font {
    padding-left: 24px;
    padding-top: 16px;
    font-family: 'Roboto';
    font-style: 'normal';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    align-self: center;
    padding-bottom: 16px;

}

.admin-settings-input-text {

    padding-right: 24px;
    padding-top: 16px;
    font-family: 'Roboto';
    font-style: 'normal';
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    text-align: right;
    padding-bottom: 16px;
}

.hide {
    display: none;
}


.settingField:hover .hide {
    display: block;
}
.ozAgentSettings{
    margin-left: 9px;
}