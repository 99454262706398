.jodit-toolbar__box:not(:empty):not(:empty) {
    background-color: #ffffff;
    margin: 5px;
    width: 95%;
    border: 1px solid #E6E5E6;
    border-radius: 8px;
}

.jodit-container:not(.jodit_inline) {
    background-color: #ffffff;
    border: 1px solid #dadada;
    border-radius: 8px;
}

.jodit-toolbar__box:not(:empty):not(:empty) {
    background-color: #ffffff;
}

.jodit-ui-separator {
    border-left: 0;
    border-right: 1px solid #E6E5E6;
    cursor: default;
    margin: 2px;
    padding: 0;
    margin-top: 5px;
    margin-bottom: 5px;
}

.jodit-container:not(.jodit_inline) {
    background-color: #ffffff !important;
    border: 1px solid #dadada;
    border-radius: 8px;
}

.jodit-ui-group:last-child {
    border-bottom: 0;
    background-color: #ffffff;
}

.jodit-toolbar-button,
.jodit-toolbar-button button {
    min-width: 24px !important;
    /*  height: 26px !important; */
    /* background-color: red; */
}

.jodit-container {
    border-radius: 8px !important;
}

.jodit-workplace {
    border-top: 1px solid#D6D6D7 !important;
    border-radius: 0px 0px 8px 8px !important;
}