/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D6D6D7;
    -webkit-transition: .4s;
    transition: .4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: #FFFFFF;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.slider {
    background-color: #3D8BF8;
}

input:focus+.slider {
    box-shadow: 0 0 1px #3D8BF8;
}

input:checked+.slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
}

/* Rounded sliders */
.slider.round {
    border-radius: 10px;
}

.slider.round:before {
    border-radius: 50%;
}


.white-bg:before {
    background-color: #3D8BF8 !important;
}

.white-bg:checked+.slider {
    background-color: #fff !important;
}

.white-bg:focus+.slider {
    background-color: #fff !important;
}


/* For the disabled Switch */

/* The switch - the box around the slider */
.switch {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 16px;
}

/* Hide default HTML checkbox */
.switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

/* The slider */
.disabled_slider {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #D6D6D7;
    -webkit-transition: .4s;
    transition: .4s;
}

.disabled_slider:before {
    position: absolute;
    content: "";
    height: 12px;
    width: 12px;
    left: 2px;
    bottom: 2px;
    background-color: #FFFFFF;
    -webkit-transition: .4s;
    transition: .4s;
}

input:checked+.disabled_slider {
    background-color: #3D8BF8;
}

input:focus+.disabled_slider {
    box-shadow: 0 0 1px #3D8BF8;
}

input:checked+.disabled_slider:before {
    -webkit-transform: translateX(14px);
    -ms-transform: translateX(14px);
    transform: translateX(14px);
}

/* Rounded sliders */
.disabled_slider.round {
    border-radius: 10px;
}

.disabled_slider.round:before {
    border-radius: 50%;
}


.white-bg:before {
    background-color: #3D8BF8 !important;
}

.white-bg:checked+.disabled_slider {
    background-color: #fff !important;
}

.white-bg:focus+.disabled_slider {
    background-color: #fff !important;
}