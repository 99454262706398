.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.css-z7uhs0-MuiStepConnector-line {
  display: none !important;
  border-color: #bdbdbd;
  border-top-style: solid;
  border-top-width: 0px;
}

.css-11lshjz-MuiButtonBase-root-MuiTab-root {
  padding: 23px 11px !important;
}

.css-196ehml-MuiButtonBase-root-MuiButton-root.Mui-disabled {
  color: #FFFFFF !important;
}

.MuiStepLabel-label {
  color: #7A7A7A !important;
  font-size: 0.875rem !important;
  line-height: 1.3125rem !important;
  cursor: pointer;
}

.MuiStepLabel-label.Mui-active {
  font-weight: 400 !important;
  color: #3D8BF8 !important;
}

.css-1gfgy7t-MuiSvgIcon-root-MuiStepIcon-root {
  color: transparent !important;
  box-sizing: border-box;
  border-radius: 50% !important;
  border: 1px dashed #7A7A7A !important;
}

.css-1gfgy7t-MuiSvgIcon-root-MuiStepIcon-root.Mui-active {
  box-sizing: border-box !important;
  border: 1px dashed #3D8BF8 !important;
  border-radius: 50% !important;
}

.css-117w1su-MuiStepIcon-text {
  fill: #7A7A7A !important;
}

.MuiStepLabel-label.Mui-completed {
  font-weight: 400 !important;
  color: #3D8BF8 !important
}

.css-1gfgy7t-MuiSvgIcon-root-MuiStepIcon-root.Mui-completed {
  fill: #3D8BF8 !important;
}

.css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
  border-radius: 12px !important;
  border: 1px solid #E6E5E6 !important;
}

.css-julti5-MuiSwitch-root {
  margin-left: 38px !important;
}

.css-jsexje-MuiSwitch-thumb {
  width: 12px !important;
  height: 12px !important;
  border-radius: 50% !important;
  /* margin-left: 0px !important; */
  /* margin-top: 0px !important; */
}