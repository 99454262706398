.widget-search-container {
	height: 30px;
	border: 1px solid #99a0a8;
	border-radius: 8px;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	z-index: 10;
	position: relative;
}
.widget-search-container img {
	cursor: pointer;
}
.input-spacing {
	top: 6px;
	position: relative;
}

.widget-search-container .drawer-search-bar input {
	border: none;
	outline: none;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
	width: 100%;
}
.widget-item-draggable {
	width: 100%;
	height: 200px;
	border: 1px solid #e6e5e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
	border-radius: 6px;
	padding: 10px;
}
.widget-item-draggable img {
	width: 100%;
	height: 82%;
	user-drag: none;
	-webkit-user-drag: none;
	user-select: none;
	-moz-user-select: none;
	-webkit-user-select: none;
	-ms-user-select: none;
}
.widget-name {
	font-weight: 400;
	font-size: 12px;
	line-height: 16px;
	color: #212121;
	margin: 6px 0 0 0;
}
