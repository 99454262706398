.ozcw-scrollbar {
    scrollbar-color: rgb(190, 190, 190) rgb(240, 240, 240);
    scrollbar-width: thin;
}

::-webkit-scrollbar-track {
    background-color: transparent;
    border-radius: 50px;
    margin-top: 5px;
    margin-bottom: 5px;
}

::-webkit-scrollbar {
    width: 3px;
    background-color: transparent;
    border-radius: 5px;
}

::-webkit-scrollbar-thumb {
    background-color: rgb(190, 190, 190);
    border-radius: 8px;
}

.oz-customAutoComplete-chip-style {
    background-color: #F5F5F6;
    color: #536580;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
    margin-top: 4px;
    margin-bottom: 4px;
    margin-left: 4px;
    display: flex;
    align-self: center;
    max-width: 300px !important;
    height: auto !important;
    padding: 5px !important;
}

.oz-customAutoComplete-chip-style .MuiChip-label {
    color: #536580;
    word-break: break-all !important;
    white-space: normal !important;
    text-overflow: initial !important;
}