.oz-admin-Campaign-container .campaign-icon {
    display: none;
    font-size: large;
    color: #99A0A8;

}

.oz-admin-Campaign-container .ag-row-hover .campaign-icon {
    display: block;
    right: 0;
    position: fixed;
    margin-right: 10px;
    cursor: pointer;
}

.icon {
    right: 0;
    position: static;
    margin-right: 15px;
    cursor: pointer;
    font-size: large;
}

.rc-time-picker-input {
    border-radius: 8px !important;
    background: #FFFFFF !important;
    border: 1px solid #D6D6D7 !important;
    border-radius: 8px !important;
    /* width: 209px !important; */
    height: 44px !important;
    font-size: 14px !important;
    color: #212121 !important;
    padding-left: 14px !important;
}

.campaign-dropzone-text {
    font-size: 0.875rem !important;
    /* or something */
    color: #99A0A8 !important;
    position: absolute;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1rem !important;
    width: 12.313rem !important;
    height: 1rem !important;
    margin-left: -45px !important;
    margin-top: 14px !important;
    /* left: 8.6rem; */
    /* top: 3rem; */
}

.campaign-drop-zone-area {
    background: #FFFFFF !important;
    border: 0.063rem solid #D6D6D7 !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    /* height: 2rem !important; */
    min-height: 2.85rem !important;
}

.mapping-dropzone-text {
    font-size: 0.875rem !important;
    /* or something */
    color: #99A0A8 !important;
    position: absolute;
    font-family: 'Roboto' !important;
    font-style: normal !important;
    font-weight: 400 !important;
    line-height: 1rem !important;
    width: 12.313rem !important;
    height: 1rem !important;
    margin-left: -45px !important;
    margin-top: 14px !important;
    /* left: 8.6rem; */
    /* top: 3rem; */
}

.mapping-drop-zone-area {
    background: #FFFFFF !important;
    border: 0.063rem solid #D6D6D7 !important;
    border-radius: 0.5rem !important;
    width: 100% !important;
    /* height: 2rem !important; */
    min-height: 2.85rem !important;
}

.campaign-drop-zone-area .css-1qkoffw .css-zk0183 .css-bdnwo0-MuiButtonBase-root-MuiChip-root {
    margin-top: -26px !important;
}

.mapping-drop-zone-area .css-1qkoffw .css-zk0183 .css-bdnwo0-MuiButtonBase-root-MuiChip-root {
    margin-top: -33px !important;
}