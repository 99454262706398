.oz-data-management-send-to-campaign-container {
    margin-top: 20px;
    height: calc(100vh - 200px);
    background-color: #FFFFFF;
    border-radius: 12px;
}

.oz-data-management-send-to-campaign-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    border-bottom: 1px solid #E6E5E6;
    height: 76px;
    padding: 0px 20px;
}

.oz-data-management-send-to-campaign-content {
    padding: 20px;
    height: calc(100vh - 380px);
    overflow-y: auto;
}

.oz-data-management-send-to-campaign-footer {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 84px;
    border-top: 1px solid #D2D2D2;
    gap: 10px;
}

.oz-data-management-send-to-campaign-update-button.MuiButton-root {
    background-color: #3D8BF8;
    border-radius: 8px;
    width: 98px;
    height: 36px;
    font-size: 12px;
    font-weight: 400;
}

.oz-data-management-send-to-campaign-cancel-button.MuiButton-root {
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 98px;
    height: 36px;
    font-size: 12px;
    font-weight: 400;
    color: #546881;
    border: 1px solid #546881;
}

.oz-data-management-send-to-campaign-remove-button.MuiButton-root {
    background-color: #E65B5C;
    border-radius: 8px;
    width: 140px;
    height: 36px;
    color: #FFFFFF;
    font-size: 12px;
    font-weight: 400;
}

.oz-data-management-send-to-campaign-remove-button.MuiButton-root:hover {
    background-color: #E65B5C;
    opacity: 0.8;
}

.oz-data-management-send-to-campaign-autocomplete-container {
    display: flex;
    gap: 2px;
    flex-direction: column;
    width: 40%;
    min-width: 350px;
}

.oz-data-management-send-to-campaign-autocomplete-label.MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: #47586E;
    margin-left: 1px;
}

.oz-data-management-send-to-campaign-content-header {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 10px 0px;
    margin-top: 20px;
    border-bottom: 1px solid #D6D9DC;
}

.oz-data-management-send-to-campaign-content-title.MuiTypography-root {
    font-size: 18px;
    font-weight: 400;
    color: #546881;
}

.oz-data-management-send-to-campaign-content-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.oz-data-management-send-to-campaign-content-segment-row {
    display: flex;
    gap: 10px;
    align-items: center;
}

.oz-data-management-send-to-campaign-content-segment-row-subtitle.MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: #909DAD;
    margin-top: 16px;
}

.oz-data-management-send-to-campaign-content-row {
    display: flex;
    flex-direction: column;
    gap: 4px;
    min-width: 340px;
    width: 45%;
}

.oz-data-management-send-to-campaign-content-row-container {
    display: flex;
    gap: 10px;
    align-items: center;
}

.oz-data-management-send-to-campaign-content-row-percentage {
    display: flex;
    gap: 4px;
    flex-direction: column;
    width: 120px;
}

.oz-data-management-send-to-campaign-distribute-evenly.MuiTypography-root {
    color: #008DFF;
    font-size: 16px;
    font-weight: 400;
    cursor: pointer;
}