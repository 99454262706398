* {
	/* font-family: 'Roboto' !important; */
	font-family: 'Roboto', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

html {
	height: 100%;
}

body {
	margin: 0;
	/* font-family: 'Roboto' !important; */
	font-family: 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	height: 100%;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}

#root {
	height: inherit;
}

#root>div {
	height: inherit;
}

.title-case {
	/* text-transform: capitalize; */
}

.cursor-pointer {
	cursor: pointer;
}


.customCard {
	width: 100%;
	border: 1px solid #e6e5e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
	border-radius: 12px !important;
	min-height: 409px;
	height: inherit !important;
	max-height: 100% !important;
}

.changedCustomCard {
	width: 100%;
	border: 1px solid #e6e5e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.04) !important;
	border-radius: 12px !important;
	min-height: 409px;
	height: 100% !important;
	max-height: 100% !important;
}

.card-header {
	width: 100%;
	height: 45px;
	padding: 0 15px;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e6e5e6;
}

.card-header .card-title {
	font-weight: 600;
	font-size: 14px;
	line-height: 16px;
	color: #212121;
	display: flex;
	align-items: center;
}

.card-header .card-actions {
	display: flex;
	align-items: center;
	justify-content: flex-end;
	gap: 15px;
}

.card-header .card-actions .card-icon {
	cursor: pointer;
	display: flex;
	align-items: center;
}

.card-title-badge {
	width: fit-content;
	min-width: 50px;
	height: 18px;
	background-color: #e66b6b;
	border-radius: 12px;
	font-weight: 400;
	font-size: 12px;
	line-height: 12px;
	color: #ffffff;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-left: 8px;
}

.card-header .sub-title {
	font-weight: normal;
	color: #99a0a8;
	margin-left: 20px;
}

.highcharts-tooltip-box {
	fill: black;
	fill-opacity: 0.6;
	stroke-width: 0;
}

.highcharts-tooltip text {
	fill: white !important;
	text-shadow: 0 0 3px white;
}

.customTabs {
	min-height: 30px !important;
}

.customTabs .MuiTabs-flexContainer {
	justify-content: space-between;
}

.customTabs button.MuiTab-textColorPrimary {
	width: 100%;
	min-height: 30px !important;
	padding: 8px 16px;
	border-top: 1px solid #dbe1e6;
	border-right: 1px solid #dbe1e6;
	background-color: #f5f8fa !important;
	flex-shrink: 1;
}

.customTabs button.Mui-selected {
	background-color: #ffffff !important;
}

.customTabs button.MuiTab-textColorPrimary:last-child {
	border-right: none;
}

.customTabPanel {
	padding: 20px !important;
}

/* .custom-table-scroll::-webkit-scrollbar {
	width: 3px;
	height: 3px;
} */

/* .custom-table-scroll::-webkit-scrollbar-track {
	-webkit-box-shadow: none;
	border-radius: 0;
} */

/* .custom-table-scroll::-webkit-scrollbar-thumb {
	border-radius: 10px;
	-webkit-box-shadow: none;
	background-color: #e6e5e6;
} */

/* here i am making custom scrollar view */
.custom-table-scroll {
	--sb-track-color: #F1F1F1 !important;
	--sb-thumb-color: #E6E5E6 !important;
	--sb-size: 15px !important;
}

.custom-table-scroll::-webkit-scrollbar {
	width: var(--sb-size)
}

.custom-table-scroll::-webkit-scrollbar-track {
	background-color: var(--sb-track-color);
	border-radius: 0px;
}

.custom-table-scroll::-webkit-scrollbar-thumb {
	background-color: var(--sb-thumb-color);
	border-radius: 15px;
	border: 4px solid #F1F1F1;
	cursor: pointer;
}

.custom-table-scroll::-webkit-scrollbar-thumb:hover {
	background-color: #A9A9A9;
	/* Change color on hover */
}

/* @supports not selector(::-webkit-scrollbar) {
.custom-table-scroll {
	scrollbar-color: var(--sb-thumb-color) var(--sb-track-color) !important;
}
} */
/* here i am making custom scrollar view */

.custom-table {
	border-radius: 0 !important;
	box-shadow: none !important;
}

.custom-table .MuiTableHead-root .MuiTableRow-root {
	background-color: #f0f1f2;
	vertical-align: top;
}

.custom-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root {
	border-bottom: none;
	font-weight: 500;
	font-size: 12px;
	line-height: 14px;
	color: #000000;
	padding: 8px 16px;
	background-color: inherit;
}

.custom-table .MuiTableHead-root .MuiTableRow-root .MuiTableCell-root span {
	font-weight: 400;
}

.custom-table .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	border-bottom: none;
	color: #000000;
	padding: 14px 16px;
}

.custom-table .MuiTableBody-root .MuiTableRow-root:nth-child(even) {
	background-color: rgba(237, 240, 242, 0.5);
}

.drawer-search-container {
	width: 100%;
	height: 30px;
	margin-top: 18px;
	border: 1px solid #99a0a8;
	border-radius: 8px;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	display: flex;
	align-items: center;
	z-index: 10;
	position: relative;
}

.drawer-search-container .drawer-search-dd {
	width: 150px;
	height: 100%;
	border-right: 1px solid #99a0a8;
	background-image: url('/public/img/search-dd-arow.svg');
	background-repeat: no-repeat;
	background-position: right 10px center;
	display: flex;
	align-items: center;
	cursor: pointer;
}

.drawer-search-container .drawer-search-dd span {
	display: block;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	padding: 0 20px 0 10px;
	color: #212121;
}

.drawer-search-container .drawer-search-bar {
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: 0 12px 0 8px;
}

.drawer-search-container .drawer-search-bar img {
	cursor: pointer;
}

.drawer-search-container .drawer-search-bar input {
	width: calc(100% - 20px);
	border: none;
	outline: none;
	font-weight: 400;
	font-size: 12px;
	line-height: 14px;
	color: #212121;
}

.text-center {
	text-align: center;
}

.legend-icon-dottedlines {
	width: 27px;
	height: 0px;

	border-top: 1px dashed #008dff;
}

.last-fetched-time {
	display: flex;
	align-items: center;
	justify-content: center;
	/* margin-left: 8px; */
	font-size: 12px;
	color: #99A0A8;
}

.refresh-btn {
	padding: 0px 10px 0px 10px;
	cursor: pointer;
}

.card-sub-header {
	color: #536580;
	font-weight: 400;
	margin-left: 3px;
}

.portCount-title {
	font-size: 14px;
	font-weight: 600;
	color: #99a0a8;
	font-style: normal;
	margin-left: 10px;
	white-space: nowrap;
}