.ozcw-widget-body {
    height: 55vh;
    /* max-height: 410px; */
    overflow-y: auto;
    /* overflow-x: hidden; */
    padding-top: 10px;
    box-shadow: inset rgba(0, 0, 0, 0.16) 0px 20px 21px -24px;
    padding-left: 4px;
    padding-right: 4px;
}

.ozcw-widget-message-wrapper {
    margin: 7px 7px;
    word-wrap: break-word;
}

.ozcw-message-main-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.ozcw-message-main {
    font-size: 14px;
    width: 95%;
}