.preset-item {
	background: #ffffff;
	border: 1px solid #e6e5e6;
	box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.06);
	border-radius: 6px;
	height: 274px;
	width: 313px;
	cursor: pointer;
	padding: 20px;
}

.preset-item img {
	width: 100%;
	height: 195px;
	border: 1px solid lightgray;
}

.search-input input {
	padding: 6px;
}

.preset-name {
	font-family: 'Roboto';
	font-style: normal;
	font-weight: 500;
	font-size: 14px;
	line-height: 16px;
	color: #212121;
}