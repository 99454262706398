#mode-select.MuiSelect-select.MuiSelect-outlined {
	padding: 4px 4px 4px 16px;
	font-size: 14px;
}

.sub-agentStateCounter-title{
	font-size: 14px;
	font-weight: 600;
	color: #99a0a8;
	font-style: normal;
	margin-left: 20px;
}
