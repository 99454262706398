.oz-data-management-files-add-form-content-item-drop-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 222px;
    border: 1px solid #D6D9DC;
    margin-top: 20px;
    padding: 16px 0px 12px 0px;
    border-radius: 8px;
}

.oz-data-management-files-add-form-content-item-file {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 4px;
}

.oz-data-management-files-add-form-content-item-file-text.MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: #546881;
}

.oz-data-management-files-add-form-content-item-file-info-bold.MuiTypography-root {
    font-size: 14px;
    font-weight: 600;
    color: #909DAD;
}

.oz-data-management-files-add-form-content-item-file-button.MuiButton-root {
    border-radius: 8px;
    width: 300px;
    height: 30px;
    font-size: 12px;
    font-weight: 400;
    color: #546881;
    background-color: #008DFF;
    color: #FFFFFF;
}

.oz-data-management-files-add-form-content-item-file-info.MuiTypography-root {
    font-size: 12px;
    font-weight: 400;
    color: #B2BBC6;
}