.ozAdminSipLocationContainer {
    margin-left: 8px;
    margin-right: 8px;
}

.ozAdminSipLocationContainer .oz-page-header {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14.06px;
    width: 79px;
    height: 14px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}

.ozAdminSipLocationContainer .oz-page-heading {
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 28.13px;
    width: 134px;
    height: 28px;
    color: #212121;
    flex-grow: 1;
    align-self: center;
}

.agent-label {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    margin-bottom: 6px;
    margin-left: 1px;
    color: #212121;
}

.oz-sipLocation-drawer-buttons-container {
    bottom: 15px;
    right: 15px;
    position: absolute;
    display: flex;
    justify-content: flex-end;
}

.oz-sipLocation-cancel-save-button {
    border-radius: 8px;
    height: 44px;
    font-size: 0.875rem;
    font-weight: 400;
}

.oz-sipLocation-drawer-title-container {
    display: flex;
    align-items: center;
}

.oz-sipLocation-view-morevereticon {
    margin-right: 0.5rem;
    margin-left: 0.625rem;
    align-self: center;
    cursor: pointer;
}

.oz-sipLocation-discardButton {
    font-weight: 400;
    font-size: 14px;
    color: #3D8BF8;
    cursor: pointer;
}

.oz-sipLocation-View-Header {
    font-weight: 400;
    font-size: 12px;
    color: #99A0A8;
    margin-left: 10px;
}

.oz-sipLocation-View-Value {
    display: block;
    font-weight: 400;
    font-size: 14px;
    color: #212121;
    margin-left: 10px;
    max-width: 400px;
    word-break: break-all;
}

.oz-sipLocation-form-content {
    margin-left: 10px !important;
}