.ozcw-widget {
    background-color: transparent;    
    box-shadow: 0px 4px 4px 0px #00000040;
    position: relative;    
}

/* animations */

.animate-fade-in {
    animation: fadeInAnimation ease 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}


.animate-zoom-in {
    animation: zoomIn ease 1.5s;
    animation-iteration-count: 1;
}

@keyframes zoomIn {
    0% {
        transform: scale(.5);
    }

    100% {
        transform: scale(1);
    }
}