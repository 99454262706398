/* .top-widget{
    height: 36px;
} */
p{
    margin-top: 0px;
    margin-bottom: 0px;
}
.top-widget-container{
    width: 100%;
    height: 36px;
    border: 1px solid #E6E5E6;
    border-radius: 6px;
    background-color: #FFFFFF;
}

.grid-item-container{
    display: flex;
    flex-direction: row;
    margin-top: 6px;
}
.grid-border-item{
    height: 28px;
    
}
.img-border{
    border-right: 1px solid #E6E5E6;
    height: 100%;
    padding-right: 8px;
    padding-top: 3px;
}
.grid-border-item:not(:last-child){
    border-right: 1px solid #E6E5E6;
}
.stats-height{
    height: 36px;

}
.grid-item-heading{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    color: #212121;
    margin-right: 8px;
}
.percent{
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-right: 7px;
}
.percent-green{
    color: #4F9F52;
}
.percent-red{
    color: #E65B5C;
}