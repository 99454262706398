.oz-admin-bulk-update-tabs-container {
    max-height: 30px;
    margin-top: 16px;
}

.oz-admin-bulk-update-tabs-container .MuiTab-root {
    padding: 0px;
    max-width: 92px !important;
    flex: 1;
    border-radius: 0px;
    border: 1px solid #DBE1E6;
    background-color: #F5F8FA;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    color: #212121;
    min-height: 30px;
}

.oz-admin-bulk-update-tabs-container .MuiTab-root.Mui-selected {
    background-color: #FFFF;
    color: #008EFF;
    font-size: 12px;
    line-height: 14px;
    font-weight: 400;
    min-height: 30px;
}

.oz-admin-bulk-update-tabs-container .MuiTabs-indicator {
    height: 2px;
}

.oz-admin-bulk-update-tabs-container .MuiTabs-scroller {
    max-height: 30px;
}

.oz-admin-bulk-update-container {
    width: 100%;
    height: calc(100vh - 88px);
    overflow: auto;
}

.oz-admin-bulk-update-content {
    margin-top: 16px;
    background-color: #FFFFFF;
    border-radius: 8px;
    width: 80%;
    height: calc(100vh - 270px);
}

.oz-admin-bulk-update-content-header {
    display: flex;
    align-items: center;
    height: 44px;
    padding-left: 12px;
    border-bottom: 1px solid #E6E5E6;
}

.oz-admin-bulk-update-content-header-add-button.MuiIconButton-root {
    margin-right: 12px;
}

.oz-admin-bulk-update-content-body,
.oz-admin-bulk-update-content-body-settings-header {
    padding: 6px 0px 0px 20px;
}

.oz-admin-bulk-update-content-body-settings-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    border-bottom: 1px solid #E6E5E6;
}

.oz-admin-bulk-update-content-body-settings-body {
    display: flex;
    flex-direction: column;
    gap: 12px;
    background-color: #F6F8F9;
    padding: 12px 0px 12px 20px;
    overflow: auto;
    max-height: calc(100vh - 409px);
}

.oz-admin-bulk-update-content-body-controls-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.oz-admin-bulk-update-content-body-settings-body-item {
    display: flex;
    align-items: center;
    gap: 12px;
}

.oz-admin-bulk-update-content-body-settings-body-item-right {
    display: flex;
    align-items: center;
    gap: 4px;
    width: 100%;
    justify-content: flex-end;
}

.oz-admin-bulk-update-content-body-controls-item-select .MuiOutlinedInput-root {
    height: 44px;
    border-radius: 8px;
    font-size: 14px;
}

.oz-admin-bulk-update-content-body-controls-item-select .MuiOutlinedInput-notchedOutline {
    border: 1px solid #D6D6D7;
}

.oz-admin-bulk-update-content-body-controls-item-select .MuiOutlinedInput-root .MuiAutocomplete-input {
    padding: 0px 0px 0px 6px !important;
}

.oz-admin-bulk-update-content-body-controls-item-select-popper .MuiAutocomplete-paper {
    margin-top: 10px;
    margin-bottom: 5px;
    /* padding-left: 2px;
    padding-top: 2px;  */
    box-shadow: none !important;
    border: 1px solid #D6D6D7;
    border-radius: 8px;
    /* min-height: 40px !important; */
    max-height: 275px !important;
    overflow: hidden !important;
}

.oz-admin-bulk-update-content-body-controls-item-select-popper .MuiAutocomplete-listbox {
    padding: 0px;

    li {
        height: 24px !important;
        padding: 0px;
        display: flex;
        align-items: center;
        background-color: #FFFFFF !important;
        margin-left: -1px;
        max-width: 100%;
        word-break: break-all !important;
        white-space: normal !important;
    }
}

.oz-admin-bulk-update-content-agent-select .MuiAutocomplete-paper {
    margin-top: 10px;
    margin-bottom: 5px;
    /* padding-left: 2px;
        padding-top: 2px;  */
    box-shadow: none !important;
    border: 1px solid #D6D6D7;
    border-radius: 8px;
    /* min-height: 40px !important; */
    max-height: 275px !important;
    overflow: hidden !important;
}


.oz-admin-bulk-update-content-agent-select .MuiAutocomplete-listbox {
    padding: 12px;

    li {
        height: 24px !important;
        padding: 0px;
        display: flex;
        align-items: center;
        background-color: #FFFFFF !important;
        margin-left: -1px;
        max-width: 100%;
        word-break: break-all !important;
        white-space: normal !important;
    }
}

.oz-admin-bulk-update-content-body-controls-item-select-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    /* padding: 0px 12px; */
    height: 24px !important;
    overflow: hidden;
}

.oz-admin-bulk-update-content-body-controls-item-select-option:hover {
    background-color: #F5F8FA;
}

.oz-admin-bulk-update-content-body-settings-item {
    display: flex;
    align-items: center;
    gap: 12px;
}

.oz-admin-bulk-update-footer {
    display: flex;
    justify-content: flex-end;
    padding: 8px 40px 0px 40px;
}

.agent-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 1px;
    /* pl: 1; */
    flex-wrap: wrap;
    width: 60%;
    gap: 8px;
    margin-top: 6px;
    min-height: 40px;
    min-width: 300px;
    max-width: 504px;
    border-radius: 8px;
    border: 1px solid #D6D9DC;
    background-color: white;
    cursor: pointer;
    padding: 8px;
}

.agent-selector .MuiChip-root {
    background-color: #F6F8F9;
    border-radius: 12px;
    height: 24px;
    display: flex;
    color: #546881;
    align-items: center;
    justify-content: center;
}

.agent-selector .MuiChip-root .MuiChip-deleteIcon {
    padding: 0px;
}

.agent-selector-selected {
    flex-grow: 1;
    width: 80%;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    align-items: center;
    max-height: 60px;
    overflow-y: auto;
}