.ozAdminAgentContainer .agentMorevertIcon {
    display: none;
    cursor: pointer;
}

.ozAdminAgentContainer .ag-row-hover .agentMorevertIcon {
    display: block;
    position: fixed;
    right: 0;
    margin-right: 10px;
}

.ozAdminAgentContainer .ag-cell-wrapper {
    width: 95% !important;
}

.oz-Agent-Grid-Locked-Container {
    padding-top: 22px;
}

.oz-Agent-MoreVert-Container {
    display: flex;
    align-items: center;
    width: 100%;
    justify-content: space-between;
}

.oz-Admin-Avatar-Container {
    display: flex;
    align-items: center;
}

.ozAdminAgentContainer {
    margin-left: 5px;
    margin-right: 5px;
}

.oz-Agent-InnerDivGroup {
    display: flex;
}

.oz-Agent-ImportImage {
    margin-right: 8px;
}

.oz-Agent-Add-Form-Container {
    margin-left: 90px;
    margin-right: 90px;
}

.oz-agent-form-deleteIcon-container {
    padding-left: 2px;
    margin-top: 46px;
    cursor: pointer;
}

.oz-agent-another-level-container {
    display: flex;
}

.oz-agent-another-level-image {
    padding-right: 6px;
    padding-bottom: 3px;
}

.oz-agent-form-view-maindiv {
    display: flex;
    margin-right: 0.5rem;
    margin-left: 0.625rem;
    margin-top: 10px;

}

.oz-agent-form-view-maindiv-page-not-edit {
    margin-top: 7px;

}

.oz-agent-view-switch-container {
    margin-top: 2px;
    margin-left: 37px;
}

.oz-agent-form-view-agent-modes-container {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
}

.oz-agent-form-view-edit-container {
    display: flex;
    align-items: center;
    height: 32px;
}

.oz-agent-view-switch-deactivate-container {
    margin-top: 2px;
    margin-left: 17px;
}

.oz-agent-form-view-agent-mode-edit-button-container {
    display: flex;
}

.oz-agent-form-view-skill-list-container {
    display: flex;
    align-items: center;
    height: 38px;
    border: 1px solid #D6D6D7;
    border-radius: 8px 8px 0px 0px;
}

.oz-agent-form-view-skill-list-inner-container {
    display: flex;
    align-items: center;
    height: 38px;
    border: 1px solid #D6D6D7;
    border-top: 0px;
    border-radius: 0px 0px;
}

.oz-agent-form-view-skill-list-inner-true-border-container {
    border-bottom-left-radius: 8px;
    border-bottom-right-radius: 8px;
}

.oz-agent-form-view-skill-list-inner-false-border-container {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}

.oz-agent-form-add {
    margin-left: 15px;
    margin-top: 30px;
    border-radius: 8px;
    background-color: #FFFFFF;
}

.oz-agent-form-edit {
    width: 458px;
}

.oz-agent-form-add-checkbox {
    display: flex;
    margin-left: 4px;
}

.oz-agent-form-drawer-header-conatiner {
    display: flex;
    align-items: center;
}

.oz-agent-form-drawer-header-morevert {
    margin-right: 0.5rem;
    margin-left: 0.625rem;
    align-self: center;
    cursor: pointer;
}

.oz-agent-form-view-header-items {
    display: flex;
    flex-direction: column;
    margin-left: 0.625rem;
    margin-top: 10px;
    margin-bottom: 10px;
}

.oz-agent-form-view-maindiv-deactivate {
    margin-top: 4px;
}

.oz-agent-form-view-maindiv-edit-modes {
    display: block;
    align-items: center;
}

.oz-agent-to-group-form {
    width: 408px;
}

.oz-agent-to-group-form-buttons {
    display: flex;
    height: 260px;
    max-height: 10000px;
    justify-content: flex-end;
    align-items: flex-end;
    align-content: flex-end;
    align-self: end;
}

.oz-agent-bulk-actions-container {
    display: flex;
    margin-top: 20px;
    align-items: center;
    gap: 16px;
}

.oz-agent-bulk-actions-add-to-group-container {
    display: flex;
    align-content: center;
}

.oz-agent-bulk-actions-add-to-group-image {
    padding-right: 6px;
    padding-bottom: 3px;
}

.oz-agent-bulk-actions-add-to-group-inner {
    margin-top: 10px;
    margin-left: 20px;
    margin-right: 17px;
}

.oz-agent-bulk-actions-add-to-group-inner-icon-button {
    display: flex;
    margin-bottom: 20px;
    align-items: center;
}

.oz-admin-agent-update-content-body-controls-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.oz-admin-agent-update-content-body-controls-item-select {
    min-width: 322px;
    padding-right: 10px;
}

.oz-admin-agent-update-content-body-controls-item-select .MuiOutlinedInput-root {
    min-height: 44px;
    font-size: 14px;
    color: #212121;
    border-radius: 8px;
}

.oz-admin-agent-update-content-body-controls-item-select .MuiOutlinedInput-root.Mui-focused>fieldset {
    border: 1px solid #E5E5E5;
}

.oz-admin-agent-update-content-body-controls-item-select .MuiOutlinedInput-root:hover>fieldset {
    border: 1px solid #E5E5E5;
}

.oz-admin-agent-update-content-body-controls-item-select .MuiAutocomplete-input {
    display: block;
}

/* Conditional styles */
.temp-mode .oz-admin-agent-update-content-body-controls-item-select {
    padding-right: 0;
}

.temp-mode .oz-admin-agent-update-content-body-controls-item-select .MuiAutocomplete-input {
    display: none;
}

.add-mode .oz-admin-agent-update-content-body-controls-item-select .MuiOutlinedInput-root.Mui-focused>fieldset {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
}


.oz-admin-agent-update-content-body-controls-item-select-option {
    display: flex;
    align-items: center;
    cursor: pointer;
    margin: 2px;
    border-radius: 4px;
    height: 24px !important;
    overflow: hidden;
}

.oz-admin-agent-update-content-body-controls-item-select-option:hover {
    background-color: #F5F8FA;
}