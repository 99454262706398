.tv-monitor-container {
    padding: 17px 20px 16px 20px;
}

.tv-monitor-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 21px;
    color: #212121;
}

.wallboard-container {
    padding-left: 21px;
}

.wallboard-container-light {
    background-color: #ffffff;
}

.wallboard-container-dark {
    background-color: black;
}

.wallboard-container .wallboard-delete-icon {
    position: absolute;
    right: -10px;
    top: -10px;
    z-index: 10;
    cursor: pointer;
}

.wallboard-container .card-hide {
    display: none;
}

.wallboard-container .container-dark {
    background-color: #1e1e1e;
    box-shadow: 4px 4px 15px rgba(255, 255, 255, 0.15);
    border-radius: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    color: #e1e1e1;
}

.wallboard-container .container-light {
    background-color: #ffffff;
    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 10px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    color: #212121;
}

.sla {
    font-weight: 400;
    margin-right: 32px;
}

.sla-percent {
    font-weight: 700;
    margin-right: 16px;
}

.calls-queue-container .queue-icon-container {
    width: 83.71px;
    height: 83.71px;
    background-color: rgba(0, 141, 255, 0.2);
    border-radius: 10px;
    display: flex;
    margin-left: 19px;
}

.calls-queue-container {
    height: 131.85px;
}

.all-cards-heading {
    font-size: 31.392px;
    line-height: 37px;
}

.all-cards-count {
    font-weight: 700;
    font-size: 41.856px;
    line-height: 49px;
    margin-top: 12px;
}

.calls-queue-container .card-content-container {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
}

.total-agents-container {
    border-radius: 10px;
    margin-bottom: 0px;
    height: 148.85px;
}

.total-agents-container .queue-icon-container {
    width: 83.71px;
    height: 83.71px;
    background-color: rgba(0, 141, 255, 0.2);
    border-radius: 10px;
    display: flex;
    margin-left: 19px;
}

.total-agents-container .card-content-container {
    display: flex;
    flex-direction: column;
    margin-left: 32px;
}

.wallboard-container .total-agents-main-container {
    height: 284.62px;
}

.sub-main-container {
    border-top: 0.5232px solid #c9c9c9;
    margin-top: 0px;
    height: 136px;
}

.sub-main-container .logged-in-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 19px;
}

.sub-main-container .red-container {
    background-color: #c60000;
    border-bottom-right-radius: 10px;
}

.sub-main-container .rose-container {
    background-color: #ffcccc;
    border-bottom-right-radius: 10px;
}

.wallboard-container .ready-pause-main-container {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
}

.total-agents-main-container .ready-pause-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
}

.ready-pause-container .ready-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.wallboard-container .table-main-container {
    border-radius: 5.232px;
    padding-left: 18px;
    padding-top: 17px;
    height: 335px;
    font-family: 'Roboto';
    font-style: normal;
    margin-bottom: 30px;
}

.table-main-container .table-heading {
    font-weight: 700;
    font-size: 26.16px;
    line-height: 31px;
}

.total-agents-main-container .top-agents-heading {
    font-weight: 700;
    font-size: 26.16px;
    line-height: 31px;
    margin-top: 18px;
    margin-left: 18px;
    margin-bottom: 16.61px;
    padding-bottom: 0px;
}

.total-agents-main-container .top-agents-heading-dark {
    color: #e1e1e1;
}

.total-agents-main-container .top-agents-names-container {
    border-top: 0.5232px solid #c9c9c9;
}

.top-agents-names-container .agent-name-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding-left: 27px;
    margin-top: 27px;
}

.agent-name-container .gold-badge-holder {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 26.16px;
    line-height: 31px;
    color: #212121;
    margin-left: 40px;
}

.agent-name-container .gold-badge-holder-dark {
    color: #e1e1e1;
}

.wallboard-container .wallboard-top-widget-container {
    background-color: #eaf5ff;
    border: 1px solid #ffffff;
    border-radius: 5px;
    color: #212121;
}

.wallboard-container .wallboard-top-widget-container-dark {
    background-color: #1e1e1e;
    color: #e1e1e1;
}

.wallboard-top-widget-container .img-border {
    background-color: #ffffff;
    border-radius: 5px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px 0px 0px 0px;
    height: 36px;
    width: 36px;
}

.wallboard-grid-item-container {
    display: flex;
    flex-direction: row;
    margin-top: 6px;
}

.wallboard-grid-item-container .wallboard-grid-item-heading {
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 16px;
    margin-right: 8px;
}

.tv-monitor-container .uneditable-button {
    border: 1px solid #99a0a8;
    color: #99a0a8;
    outline: none;
}

.tv-monitor-container .uneditable-button:hover {
    background-color: transparent;
    border: 1px solid #99a0a8;
}

.wallboard-container .wallboard-widgets-grid {
    height: 100%;
}

.wallboard-container .dark-mode {
    color: #ffffff;
}

.light-opacity {
    opacity: 0.5;
}

.filter-icon {
    padding-right: 25px;
    margin-top: 5px;
    cursor: pointer;
}

.filter-outer-wallboard {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
}

.filter-outer-wallboard .filter-remove {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #3d8bf8;
    cursor: pointer;
    margin-left: 12px;
}

.filter-outer-wallboard .filter-remove-dark {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: white;
    cursor: pointer;
    margin-left: 12px;
}

.filter-container-wallboard .MuiChip-colorPrimary {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #536580;
    height: 22px;
    background: rgba(0, 142, 255, 0.1);
    border: 1px solid #008eff;
    border-radius: 15px;
}

.filter-container-wallboard-dark .MuiChip-colorPrimary {
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #f1f1f1;
    height: 22px;
    background: rgba(0, 142, 255, 0.1);
    border: 1px solid white;
    border-radius: 15px;
}

.filter-container-wallboard {
    height: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.filter-container-wallboard-dark {
    height: auto;
    overflow-x: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
}