.custom-label .MuiTypography-root {
	font-weight: 400;
	font-size: 14px;
	line-height: 16px;
	color: #212121;
}

.custom-label .MuiCheckbox-root {
	padding: 0 9px;
}

.field-label {
	margin: 0;
	font-size: 12px;
}

.no-margin {
	margin: 0;
}

b {
	font-size: 14px;
}

.call-status-container span {
	font-size: 14px;
	margin-left: 8px;
}

.call-status-container {
	display: flex;
	align-items: center;
	height: 42px;
	background: #f5f8fa;
}

.call-status {
	width: 8px;
	height: 8px;
	border-radius: 8px;
	background: #99a0a8;
}
.call-status-connected {
	width: 8px;
	height: 8px;
	border-radius: 8px;
	background: #4f9f52;
}
.call-status-ended {
	width: 8px;
	height: 8px;
	border-radius: 8px;
	background: #E65B5C;
}

.call-status-container,
.agent {
	margin-bottom: 10px;
}

.bargein-items-container {
	width: 100%;
	height: 100vh;
	margin-bottom: 10px;
	padding: 10px 0;
	overflow-y: auto;
}

.bargein-items-footer {
	width: 100%;
	height: auto;
}

.bargein-items-error {
	width: 100%;
	height: auto;
}

.bargein-items-footer button {
	width: 100%;
	background-color: #3d8bf8;
	border-radius: 8px;
}

.bargein-items-error button {
	width: 100%;
	background-color: #E65B5C;
	border-radius: 8px;
}

.bargein-status-container {
	margin-top: 100px;
	width: 100%;
}

.bargein-status-container img {
	width: 100%;
}