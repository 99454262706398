.audio-player-container {
	background: #3d8bf8;
	border-radius: 8px;
}
.waveform-container wave{
	overflow: hidden ! important;
}
.play-icon-color:not(disabled) {
	color: #fff;
}
